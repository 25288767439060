import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { mapOptionToAnswer, mapTextToAcceptedLanguage, Option, sortByIndex } from "shared";
import { RadioInput } from "../../components";
import { useAnswer, useCurrentField, useIsValid, useNavigate } from "./context";

const RadioField = () => {
  const { i18n } = useTranslation("general");
  const { language } = i18n;
  const currentField = useCurrentField();
  const setIsValid = useIsValid()[1];
  const [answer, setAnswer] = useAnswer();
  const navigator = useNavigate();

  const { options } = currentField;
  if (!options) throw new Error("Radio field must include options");

  // Effects

  useEffect(() => {
    setIsValid(answer !== undefined);
  }, [answer, setIsValid]);

  // Handlers

  const onSelect = (selectedOptionKey: string) => {
    const option = options.find(({ key }: Option) => key === selectedOptionKey);
    if (!option) return;

    const answer = mapOptionToAnswer(option);
    setAnswer(answer);

    setTimeout(navigator.onClickNext, 150);
  };

  // Rendering

  const lang = mapTextToAcceptedLanguage(language);

  return (
    <RadioInput onSelect={onSelect} value={answer ? answer.optionKey! : undefined}>
      {sortByIndex(options).map((option: Option) => (
        <RadioInput.Option key={option.key} value={option.key}>
          {option.multilingualText[lang]}
        </RadioInput.Option>
      ))}
    </RadioInput>
  );
};

export default RadioField;
