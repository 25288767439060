import React from "react";
import styled from "styled-components";
import { colors, spacing } from "../../styling.constants";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

interface StepperProps {
  children: JSX.Element[];
}

interface StepProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
}

export const Stepper = ({ children }: StepperProps) => {
  return <Wrapper>{children}</Wrapper>;
};

const Step = (props: StepProps) => {
  return <div {...props} />;
};

Stepper.Step = styled(Step)`
  flex: 1;
  height: 6px;
  border-radius: 4px;
  background-color: ${props => (props.isActive ? colors.primaryBlue : colors.lightGrey)};
  margin-right: ${spacing.s};

  &:last-of-type {
    margin-right: 0;
  }
`;
