import React from "react";

import { ConfirmationPage, FormsPage, HomePage, AssessmentPage, LegalPage } from "../pages";

const routes = {
  confirm: {
    path: "/confirmation",
    element: <ConfirmationPage />,
  },
  form: {
    path: "/forms",
    element: <FormsPage />,
  },
  home: {
    path: "/home",
    element: <HomePage />,
  },
  assessment: {
    path: "/assessments/:token",
    element: <AssessmentPage />,
  },
  legal: {
    path: "/legal",
    element: <LegalPage />,
  },
};

export const routesArray = Object.values(routes);

export default routes;
