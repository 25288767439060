import React from "react";

export const AIcon = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="28" fill="#A3E6CD" />
    <path
      d="M18.2129 38.4001V38.2401L24.4796 17.6001H31.4929L37.7862 38.2401V38.4001H33.1996L28.1596 21.0668H27.5196L22.4262 38.4001H18.2129ZM23.4129 33.2001L24.3462 29.8401H31.3329L32.2662 33.2001H23.4129Z"
      fill="#317159"
    />
  </svg>
);
