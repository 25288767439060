import styled from "styled-components";
import { colors, navBarHeight, spacing } from "ui-kit";
import { Nav } from "../nav";

interface PageWrapperProps {
  withNav?: boolean;
  lightBackground?: boolean;
  children: React.ReactNode;
}

const Page = (props: PageWrapperProps) => {
  return (
    <div {...props}>
      {props.withNav && <Nav lightBackground={props.lightBackground} />}
      {props.children}
    </div>
  );
};

export const PageWrapper = styled(Page)`
  width: 100vw;
  min-height: 100vh;
  padding: 0 ${spacing.m};
  padding-top: ${(props) => (props.withNav ? navBarHeight : 0)};
  background-color: ${(props) => (props.lightBackground ? colors.background : undefined)};
  box-sizing: border-box;
`;
