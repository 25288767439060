import React from "react";
import styled from "styled-components";
import { colors, Container, Logo, navBarHeight, navBarWidth, spacing, navBarShadow } from "ui-kit";
import useScroll from "../../utils/use-scroll";
import LanguagePicker from "./language-picker";

const SCROLL_SHADOW_TRESHOLD = 100;

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 ${spacing.m};
  box-sizing: border-box;
  z-index: 5;
  transition: all 0.3s;

  & button {
    margin-left: 5px;
  }
`;

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${navBarHeight};
  max-width: ${navBarWidth};
  overflow: visible;

  & div {
    display: flex;
    align-items: center;
  }
`;

interface NavProps extends React.HTMLAttributes<HTMLDivElement> {
  lightBackground?: boolean;
}

const _Nav = (props: NavProps) => {
  const { scrollY } = useScroll();

  const style = {
    ...props.style,
    boxShadow: scrollY > SCROLL_SHADOW_TRESHOLD ? navBarShadow : undefined,
  };

  return (
    <Wrapper {...props} style={style}>
      <NavContainer>
        <Logo />
        <LanguagePicker />
      </NavContainer>
    </Wrapper>
  );
};

export const Nav = styled(_Nav)`
  background-color: ${(props) => (props.lightBackground ? colors.background : colors.white)};
`;
