export const mapOptionValueToTranslationKey = (optionValue: string): string => {
  switch (optionValue) {
    case "individual":
      return "theIndividual";
    case "caregiver":
      return "theCaregiver";
    default:
      return optionValue;
  }
};
