import React from "react";

export const CIcon = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg {...props} width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2661 21.8002C7.24387 21.8002 4.8972 20.8491 3.22609 18.9469C1.57276 17.0446 0.746094 14.3958 0.746094 11.0002C0.746094 7.6402 1.56387 5.0002 3.19943 3.0802C4.83498 1.1602 7.18165 0.200195 10.2394 0.200195C12.0705 0.200195 13.635 0.546862 14.9328 1.2402C16.2305 1.91575 17.2439 2.85797 17.9728 4.06686C18.7017 5.27575 19.1283 6.66242 19.2528 8.22686H14.7994C14.6572 6.76908 14.1861 5.63131 13.3861 4.81353C12.5861 3.97797 11.5461 3.5602 10.2661 3.5602C8.64832 3.5602 7.43054 4.20908 6.61276 5.50686C5.79498 6.80464 5.38609 8.64464 5.38609 11.0269C5.38609 13.498 5.80387 15.3558 6.63943 16.6002C7.49276 17.8269 8.69276 18.4402 10.2394 18.4402C11.5728 18.4402 12.6305 18.0135 13.4128 17.1602C14.2128 16.3069 14.675 15.178 14.7994 13.7735H19.2528C19.1283 15.338 18.7017 16.7246 17.9728 17.9335C17.2439 19.1424 16.2305 20.0935 14.9328 20.7869C13.6528 21.4624 12.0972 21.8002 10.2661 21.8002Z"
      fill="#66460D"
    />
  </svg>
);
