import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RadioInput } from "../../components";
import FormWrapper from "../../components/form/wrapper";
import { useTranslation } from "react-i18next";
import { usePatientStore } from "../../stores";
import { observer } from "mobx-react";

const PatientUserRelationForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("forms");
  const patientStore = usePatientStore();

  // States

  const [relation, setRelation] = useState(patientStore.patientUserRelation);

  // Effects

  useEffect(() => {
    if (relation) setTimeout(() => patientStore.setPatientUserRelation(relation), 150);
  }, [patientStore, relation]);

  // Fields

  return (
    <FormWrapper
      fieldKey={"patient_user_relation_field"}
      fieldTitle={t("patientUserRelationFieldTitle")}
      onClickNext={() => patientStore.setPatientUserRelation(relation)}
      onClickPrevious={() => navigate(-1)}
      isValid={relation !== undefined}>
      <RadioInput value={relation} onSelect={setRelation}>
        {["self", "other"].map((key) => (
          <RadioInput.Option key={key} value={key}>
            {t(key)}
          </RadioInput.Option>
        ))}
      </RadioInput>
    </FormWrapper>
  );
};

export default observer(PatientUserRelationForm);
