import React, { Dispatch, SetStateAction } from "react";
import { Dialog, IconButton, CrossIcon, toaster } from "evergreen-ui";
import { Formik, Form } from "formik";
import { Button, spacing, Star } from "ui-kit";
import { Assessment, contactDaysArray, ContactPerson, contactPersonArray, contactTimeArray } from "shared";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { usePatientStore, useStore } from "../../stores";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { FormikSelect } from "../../components";
import { CheckboxGroup } from "../../components/styled-formik-fields";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { fadeIn } from "../../utils/animations";

const FormHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const BlockWrapper = styled.div`
  animation-name: ${fadeIn};
  animation-duration: 1s;

  margin-bottom: ${spacing.l};
  & > p {
    margin: ${spacing.s} 0px;
    font-weight: 600;
  }
`;

const ButtonWrapper = styled.div`
  margin: ${spacing.m} 0;
  display: flex;
  justify-content: flex-end;
`;

interface ContactMeFormProps {
  contactFormIsShown: boolean;
  setContactFormIsShown: Dispatch<SetStateAction<boolean>>;
  setAssessment: Dispatch<SetStateAction<Assessment | undefined>>;
}

interface ContactMeFormValues {
  contactPerson?: ContactPerson;
  contactDays: { weekdays: boolean; weekends: boolean };
  contactTime: { am: boolean; pm: boolean; evenings: boolean };
}

const initialValues = {
  contactPerson: undefined,
  contactDays: { weekdays: false, weekends: false },
  contactTime: { am: false, pm: false, evenings: false },
};

const ContactForm = ({ contactFormIsShown, setContactFormIsShown, setAssessment }: ContactMeFormProps) => {
  const { t } = useTranslation("general");
  const { t: ta } = useTranslation("assessment");
  const patientStore = usePatientStore();
  const rootStore = useStore();
  const { token } = useParams();

  const validationSchema = Yup.object().shape({
    contactPerson: Yup.string()
      .oneOf([...contactPersonArray], t("invalidInputError"))
      .required(t("requiredFieldError")),
  });

  const { mutateAsync: onSubmit, isLoading } = useMutation(async (values: ContactMeFormValues) => {
    if (!token) return;

    const contactDays = Object.keys(_.pickBy(values.contactDays, _.identity)); // construct an array of attributes whose values are true
    const contactTime = Object.keys(_.pickBy(values.contactTime, _.identity));
    const payload = { contactPerson: values.contactPerson, contactDays, contactTime };

    const assessment = await rootStore.executeRequest<Assessment>({
      url: `/assessments/${token}`,
      method: "put",
      data: payload,
    });

    if (assessment) {
      setAssessment(assessment);
      setContactFormIsShown(false);
      toaster.success(ta("contactSuccessSubmit"));
    }
  });

  const Header = () => (
    <FormHeaderContainer>
      <h1>{ta("contactMeFormTitle")}</h1>
      <IconButton onClick={() => setContactFormIsShown(false)} icon={CrossIcon} border={"none"} size={"large"} />
    </FormHeaderContainer>
  );

  return (
    <Dialog
      isShown={contactFormIsShown}
      hasFooter={false}
      header={Header}
      width={700}
      onCloseComplete={() => setContactFormIsShown(false)} // When left clicking outside the Dialog
    >
      <Formik<ContactMeFormValues>
        initialValues={initialValues}
        validationSchema={patientStore.patientUserRelation !== "self" ? validationSchema : undefined}
        onSubmit={(values) => onSubmit(values)}>
        {({ values, errors, touched, isValid }) => {
          const atLeastOneCheckboxPerGroupIsChecked =
            Object.values(values.contactDays).includes(true) && Object.values(values.contactTime).includes(true);
          return (
            <Form>
              {patientStore.patientUserRelation !== "self" && (
                <BlockWrapper>
                  <FormikSelect
                    path="contactPerson"
                    t={t}
                    placeHolder={ta("contactPersonSelect")}
                    isRequired
                    OptionsArray={contactPersonArray}
                    label={ta("contactPersonLabel")}
                    touched={touched}
                    errors={errors}
                    differentTranlsationkeys
                  />
                </BlockWrapper>
              )}
              {(values.contactPerson || patientStore.patientUserRelation === "self") && (
                <>
                  <BlockWrapper>
                    <p>
                      <Star /> {ta("dateTimeTitle")}
                    </p>
                    <p>{ta("days")}</p>
                    <CheckboxGroup path={"contactDays"} elementsArray={contactDaysArray} t={ta} values={values} />
                    <p>{ta("hours")}</p>
                    <CheckboxGroup path={"contactTime"} elementsArray={contactTimeArray} t={ta} values={values} />
                  </BlockWrapper>
                  <ButtonWrapper>
                    <Button
                      type="submit"
                      appearance="primary"
                      disabled={!isValid || !atLeastOneCheckboxPerGroupIsChecked}
                      isLoading={isLoading}>
                      {t("validate")}
                    </Button>
                  </ButtonWrapper>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ContactForm;
