import { CaretDownIcon } from "evergreen-ui";
import React from "react";
import styled from "styled-components";
import { colors, componentBorderWidth, focusShadow, fonts, spacing } from "../../styling.constants";

export const Wrapper = styled.div`
  position: relative;

  &.invalid select {
    border: ${componentBorderWidth} solid ${colors.primaryRed};
    color: ${colors.darkRed};
  }
`;

export const CaretHolder = styled.div`
  width: 20px;
  height: 20px;
  margin: auto;
  position: absolute;
  right: ${spacing.s};
  top: 0;
  bottom: 0;
  background-color: ${colors.secondaryBlue};
  color: ${colors.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${spacing.xxs};
  pointer-events: none;

  &.invalid {
    background-color: ${colors.mediumRed};
    color: ${colors.darkRed};
  }
`;

const StyledSelect = styled.select`
  border: ${componentBorderWidth} solid ${colors.lightGrey};
  border-radius: ${spacing.s};
  height: ${spacing.xl};
  padding: 0 ${spacing.s};
  font-family: ${fonts.body2.family};
  font-size: ${fonts.body2.size};
  font-weight: ${fonts.body2.weight};
  color: ${colors.darkGrey};
  background-color: ${colors.white70};
  appearance: none;

  &:focus,
  &:active,
  &:focus-visible {
    border: ${componentBorderWidth} solid ${colors.secondaryBlue};
    color: ${colors.darkGrey};
    outline: none;
    box-shadow: ${focusShadow};
  }
`;

interface SelectProps extends React.HTMLAttributes<HTMLSelectElement> {
  isInvalid?: boolean;
}

// Note: The select was styled without using the evergreen library because it was simple as such
export const Select = (props: SelectProps) => {
  return (
    <Wrapper className={props.isInvalid ? "invalid" : undefined}>
      <StyledSelect {...props} />
      <CaretHolder className={props.isInvalid ? "invalid" : undefined}>
        <CaretDownIcon />
      </CaretHolder>
    </Wrapper>
  );
};
