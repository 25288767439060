import React from "react";
import { RootStore } from "../stores/root";

const rootStore = new RootStore();
const storeContext = React.createContext<RootStore | null>(null);

interface StoreProviderProps {
  children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  return <storeContext.Provider value={rootStore}>{children}</storeContext.Provider>;
};

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};

export const usePatientStore = () => {
  const store = useStore();
  return store.patientStore;
};

export const useFormStore = () => {
  const store = useStore();
  return store.formStore;
};
