export const colors = {
  background: "#EFF2F1",

  lightBlue: "#D9DEEF",
  secondaryBlue: "#9DB5FF",
  thirdBlue: "#6B9AC4",
  primaryBlue: "#4059AD",
  darkBlue: "#253776",
  shadowBlue: "#9DB5FFCC",

  white: "#ffffff",
  white70: "#FFFFFFB2",
  lightGrey: "#D8DAE5",
  darkGrey: "#474D66",
  almostBlack: "#101840",
  neutral: "#101840",

  lightRed: "#FDF4F4",
  mediumRed: "#F4B6B6",
  primaryRed: "#D14343",
  darkRed: "#7D2828",

  gradeA: "#A3E6CD",
  gradeB: "#A3E6CD",
  gradeC: "#FFEFD2",
  gradeD: "#F9DADA",
};

export const fonts = {
  headline1: {
    family: "Familjen Grotesk",
    weight: "700",
    size: "32px",
  },
  subtitle1: {
    family: "Open sans",
    weight: "600",
    size: "20px",
  },
  subtitle2: {
    family: "Open sans",
    weight: "400",
    size: "20px",
  },
  body1: {
    family: "Open sans",
    weight: "600",
    size: "18px",
  },
  body2: {
    family: "Open sans",
    weight: "400",
    size: "18px",
  },
  caption1: {
    family: "Open sans",
    weight: "600",
    size: "14px",
  },
  caption2: {
    family: "Open sans",
    weight: "400",
    size: "14px",
  },
};

export const spacing = {
  xxs: "4px",
  xs: "8px",
  s: "16px",
  m: "24px",
  l: "32px",
  xl: "56px",
  xxl: "80px",
};

export const navBarHeight = spacing.xl;
export const navBarWidth = "1000px";
export const componentBorderWidth = "2px";
export const mobileTreshold = 900;

export const focusShadow = `0 0 0 2px ${colors.lightBlue}`;
export const navBarShadow = `0px 4px 8px 0px ${colors.shadowBlue}`;
