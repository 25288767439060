import React from "react";

export const Avatar = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    {...props}
    style={{ minWidth: "60px" }}
    width="60"
    height="63"
    viewBox="0 0 60 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2257_11668)">
      <path
        d="M60 30.0308C60 35.8154 58.3385 41.1692 55.5692 45.7846C54.7692 47.0769 53.9077 48.2461 52.9846 49.3538C52.4308 50.0308 51.8769 50.6462 51.2615 51.2615C50.8923 51.6308 50.5231 52 50.0923 52.3692C49.9692 52.4923 49.8462 52.6154 49.6615 52.7385C49.1692 53.1692 48.6154 53.6 48.1231 53.9692C47.6923 54.2769 47.3231 54.5846 46.8923 54.8923C46.7692 55.0154 46.6462 55.0769 46.4615 55.2C46.4 55.2615 46.3385 55.2615 46.2769 55.3231C45.5385 55.8154 44.8 56.2461 44.0615 56.6154C43.9385 56.6769 43.8769 56.7385 43.7538 56.8C43.5692 56.9231 43.3846 56.9846 43.1385 57.1077C42.2154 57.5385 41.2308 57.9692 40.2462 58.3385C38.9538 58.8308 37.6 59.2 36.2462 59.5077C34.2769 59.9385 32.1846 60.1231 30.0923 60.1231C29.1692 60.1231 28.3077 60.0615 27.3846 60C26.4615 59.9385 25.5385 59.8154 24.6154 59.6308C24.1231 59.5077 23.6308 59.4462 23.1385 59.3231C22.0923 59.0769 21.0462 58.7692 20 58.4C19.6308 58.2769 19.3231 58.1538 18.9538 57.9692C18.7077 57.8461 18.4615 57.7846 18.2154 57.6615C17.2923 57.2308 16.3692 56.8 15.5077 56.3077C15.3846 56.2462 15.2615 56.1846 15.1385 56.1231C14.4 55.6923 13.6615 55.2615 12.9231 54.7692C12.3077 54.3385 11.6923 53.9077 11.1385 53.4154C11.0769 53.3538 11.0154 53.2923 10.9538 53.2308C10.1538 52.5538 9.35385 51.8154 8.61539 51.0769C8.36923 50.8923 8.18462 50.6462 8 50.4C7.44615 49.7846 6.89231 49.1077 6.33846 48.4923C6.15385 48.3077 6.03077 48.0615 5.84615 47.8769C5.47692 47.4462 5.16923 46.9538 4.86154 46.5231C1.78462 41.6615 0 36.0615 0 30.0308C0 13.4154 13.4154 0 30.0308 0C46.5846 0 60 13.4154 60 30.0308Z"
        fill="#FFCE50"
      />
    </g>
    <path
      d="M28.8623 11.1385C28.8623 11.1385 23.0161 10.2769 18.8315 12.8615C14.6469 15.5077 16.5546 22.4615 16.5546 22.4615C16.5546 22.4615 9.84688 27.6923 9.47765 33.4769C9.10842 39.2615 14.3392 41.3538 14.3392 41.3538L28.1853 39.2615L28.8623 11.1385Z"
      fill="black"
    />
    <path
      d="M55.5698 45.7846C54.7698 47.0769 53.9083 48.2461 52.9852 49.3538C52.4314 50.0308 51.8775 50.6461 51.2621 51.2615C50.2775 52.2461 49.2314 53.1692 48.1237 53.9692C47.6929 54.2769 47.3237 54.5846 46.8929 54.8923C46.7698 55.0154 46.6468 55.0769 46.4621 55.2C46.4006 55.2615 46.3391 55.2615 46.2775 55.3231C45.5391 55.8154 44.8006 56.2461 44.0621 56.6154L41.5391 35.0154C41.5391 35.0154 43.9391 35.5077 49.5391 40.3692C53.7237 44.0615 55.1391 45.3538 55.5698 45.7846Z"
      fill="#92B4EA"
    />
    <path
      d="M46.8307 54.8923C46.7076 55.0153 46.5846 55.0769 46.3999 55.1999C46.3384 55.2615 46.2769 55.2615 46.2153 55.323C45.4153 55.8769 44.5538 56.3692 43.6922 56.8C43.5076 56.923 43.323 56.9846 43.0769 57.1076C42.1538 57.5384 41.1692 57.9692 40.1846 58.3384C38.8923 58.8307 37.5384 59.1999 36.1846 59.5076C34.2153 59.9384 32.123 60.123 30.0307 60.123C29.1076 60.123 28.2461 60.0615 27.323 60C26.3999 59.9384 25.4769 59.8153 24.5538 59.6307C24.0615 59.5076 23.5692 59.4461 23.0769 59.323C21.6615 59.0153 20.2461 58.5846 18.8922 58.0307C18.6461 57.9076 18.3999 57.8461 18.1538 57.723C17.9076 47.5692 17.9076 37.723 18.523 36.8615C19.8153 35.0769 24.923 33.2307 29.9692 33.1076C35.0153 32.9846 41.4769 35.0769 41.4769 35.0769L46.8307 54.8923Z"
      fill="#92B4EA"
    />
    <path
      d="M50.0308 52.3692C49.9077 52.4923 49.7846 52.6153 49.6 52.7384C49.6 52.6769 49.6 52.6153 49.6 52.6153C48.6154 47.9384 45.5385 43.0769 45.5385 43.0154C45.4769 42.8923 45.4769 42.7692 45.6 42.7077C45.7231 42.6461 45.8462 42.6461 45.9077 42.7692C45.9692 42.7692 49.0462 47.6307 50.0308 52.3692Z"
      fill="white"
    />
    <path
      d="M28.1842 44.6769L24.8611 58.9538L19.9995 58.3385C19.6303 58.2154 19.3226 58.0923 18.9534 57.9077C18.7072 57.7846 18.4611 57.7231 18.2149 57.6C17.2919 57.1692 16.3688 56.7385 15.5072 56.2461C15.3842 56.1846 15.2611 56.1231 15.138 56.0615C14.3995 55.6308 13.6611 55.2 12.9226 54.7077C12.3072 54.2769 11.6919 53.8461 11.138 53.3538C11.0765 53.2923 11.0149 53.2308 10.9534 53.1692C10.1534 52.4923 9.3534 51.7538 8.61493 51.0154C8.36878 50.8308 8.18416 50.5846 7.99955 50.3385C7.4457 49.7231 6.89186 49.0461 6.33801 48.4308C6.1534 48.2461 6.03032 48 5.8457 47.8154C14.3995 33.4154 26.8919 33.0461 26.8919 33.0461L28.1842 44.6769Z"
      fill="#92B4EA"
    />
    <path
      d="M26.8301 33.7847C26.8301 33.7847 29.2301 37.3539 32.0608 39.4462C34.8916 41.6001 36.1839 41.8462 36.1839 41.8462L35.9993 34.9539L26.8301 33.7847Z"
      fill="#6876AA"
    />
    <path
      d="M26.9532 26.7076L26.8301 33.5384C26.8301 33.5384 27.9993 35.5076 31.9993 35.9384C34.9532 36.2461 35.9993 35.5692 35.9993 35.5692L35.6916 32.0615L35.3224 28.6769L28.1839 26.9538L26.9532 26.7076Z"
      fill="#F48462"
    />
    <path
      d="M28.1855 27.0154C28.1855 27.2 28.2471 27.4461 28.3702 27.6923C29.8471 31.1385 33.9702 31.9385 35.6932 32.1231L35.324 28.7385L28.1855 27.0154Z"
      fill="black"
    />
    <path
      d="M37.1077 13.2307C37.1077 13.2307 40.5539 21.8461 39.0154 26.523C37.5385 31.2615 33.2308 30.3384 30.6462 29.3538C28.0616 28.3692 26.9539 26.7077 26.9539 26.7077C26.9539 26.7077 24.4923 27.4461 22.9539 25.1692C21.4154 22.8307 22.4616 20.3077 23.877 19.7538C25.2923 19.2 26.8923 20.2461 26.8923 20.2461L25.6616 17.723C25.7231 17.723 31.5693 5.41535 37.1077 13.2307Z"
      fill="#F48462"
    />
    <path
      d="M26.5842 23.8769C26.5226 23.8769 26.4611 23.8769 26.4611 23.8154L23.4457 21.5384C23.3226 21.4769 23.3226 21.2923 23.3842 21.2307C23.4457 21.1077 23.6303 21.1077 23.6919 21.1692L26.7072 23.4461C26.8303 23.5077 26.8303 23.6923 26.7688 23.7538C26.7688 23.8769 26.6457 23.8769 26.5842 23.8769Z"
      fill="black"
    />
    <path
      d="M23.8145 23.8154C23.6914 23.8154 23.6299 23.7539 23.5684 23.6308C23.5684 23.5077 23.6299 23.3847 23.753 23.3231L25.7837 22.8308C25.9068 22.7693 26.0299 22.8924 26.0914 23.0154C26.0914 23.1385 26.0299 23.2616 25.9068 23.3231L23.8145 23.8154Z"
      fill="black"
    />
    <path
      d="M31.9379 16.8C31.8764 16.8 31.8764 16.8 31.8149 16.7385C31.6918 16.6769 31.6918 16.4923 31.7533 16.4308C32.3687 15.4462 33.3533 15.3846 33.7841 15.5077C33.9072 15.5692 33.9687 15.6923 33.9687 15.8154C33.9072 15.9385 33.7841 16 33.661 16C33.5995 16 32.7379 15.8154 32.1841 16.6769C32.061 16.7385 31.9995 16.8 31.9379 16.8Z"
      fill="black"
    />
    <path
      d="M37.9695 16.4923C37.908 16.4923 37.8464 16.4923 37.7849 16.4308C37.108 15.8154 36.7387 16 36.7387 16C36.6157 16.0615 36.4926 16 36.431 15.8769C36.3695 15.7539 36.3695 15.6308 36.4926 15.5692C36.5541 15.5077 37.108 15.2 38.0926 16.0615C38.2157 16.1231 38.2157 16.3077 38.0926 16.4308C38.0926 16.4308 38.031 16.4923 37.9695 16.4923Z"
      fill="black"
    />
    <path
      d="M36.6764 22.9538C36.6149 22.9538 36.6149 22.9538 36.5533 22.9538C36.4302 22.8923 36.3687 22.7692 36.4302 22.6461C37.1072 21.1692 35.7533 18.9538 35.7533 18.8923C35.6918 18.7692 35.6918 18.6462 35.8149 18.5846C35.9379 18.5231 36.061 18.5231 36.1225 18.6461C36.1841 18.7692 37.5995 21.1077 36.7995 22.8308C36.861 22.8923 36.7995 22.9538 36.6764 22.9538Z"
      fill="black"
    />
    <path
      d="M37.8459 19.7539C37.7228 19.7539 37.6612 19.6923 37.5997 19.5693L37.2305 17.9693C37.2305 17.9077 37.2305 17.8462 37.2305 17.7846L37.7228 16.9231C37.7843 16.8 37.9074 16.7385 38.0305 16.8C38.1535 16.8616 38.2151 16.9846 38.1535 17.1077L37.7228 17.9077L38.092 19.3846C38.1535 19.5693 38.0305 19.6923 37.8459 19.7539C37.9074 19.7539 37.9074 19.7539 37.8459 19.7539Z"
      fill="black"
    />
    <path
      d="M33.5392 19.7539C33.4161 19.7539 33.3546 19.6924 33.2931 19.5693L32.9238 17.9693C32.9238 17.9077 32.9238 17.8462 32.9854 17.7847L33.6623 16.9231C33.7238 16.8001 33.9084 16.8001 34.0315 16.8616C34.1546 16.9231 34.1546 17.1077 34.0931 17.2308L33.4777 18.0308L33.8469 19.5077C33.8469 19.5693 33.7238 19.6924 33.5392 19.7539C33.6008 19.7539 33.6008 19.7539 33.5392 19.7539Z"
      fill="black"
    />
    <path
      d="M26.8931 20.4308L28.1238 22.0924C28.1238 22.0924 30.77 18.4001 30.77 16.3693C30.77 14.3385 30.77 13.6001 30.77 13.6001C30.77 13.6001 33.4162 14.5847 35.6315 14.5231C37.8469 14.4616 39.2008 13.2924 39.0777 12.2462C38.9546 11.2001 37.6623 8.00005 30.77 9.04621C23.9392 10.0924 23.8162 18.0924 26.8931 20.4308Z"
      fill="black"
    />
    <path
      d="M39.4459 42.8924C39.3844 42.8924 32.3074 42.3385 26.6459 33.6616C26.5844 33.5385 26.5844 33.4154 26.7074 33.3539C26.8305 33.2924 26.9536 33.2924 27.0151 33.4154C32.492 41.9077 39.3228 42.4001 39.3844 42.4616C39.5074 42.4616 39.6305 42.5847 39.6305 42.7077C39.6305 42.7693 39.569 42.8924 39.4459 42.8924Z"
      fill="white"
    />
    <path
      d="M36.1222 41.8462C35.9991 41.8462 35.876 41.7231 35.876 41.6001L35.6914 35.0154C35.6914 34.8924 35.8145 34.7693 35.9376 34.7693C36.0606 34.7693 36.1837 34.8924 36.1837 35.0154L36.3683 41.6001C36.3683 41.7231 36.3068 41.7847 36.1222 41.8462Z"
      fill="white"
    />
    <path
      d="M35.5084 25.9076C35.4469 25.9076 35.3854 25.9076 35.3238 25.9076C34.6469 25.7846 34.0931 24.6769 33.97 24.5538C33.9084 24.4923 33.9084 24.3692 33.97 24.3076C34.0315 24.2461 34.0931 24.1846 34.1546 24.1846H36.8008C36.8623 24.1846 36.9854 24.2461 36.9854 24.3076C37.0469 24.3692 37.0469 24.4923 36.9854 24.5538C36.9854 24.5538 36.5546 25.2307 35.8161 25.723C35.8161 25.9076 35.6931 25.9076 35.5084 25.9076ZM34.7084 24.7384C34.9546 25.0461 35.2623 25.4153 35.5084 25.4769C35.57 25.4769 35.6315 25.4769 35.6931 25.4153C36.0008 25.1692 36.2469 24.923 36.4315 24.7384H34.7084Z"
      fill="black"
    />
    <path
      d="M24.6144 41.2923C24.4914 41.2923 24.3683 41.1692 24.3068 41.0461C23.5683 37.9692 23.7529 35.6923 24.7375 34.1538C25.5991 32.8615 26.7683 32.6769 26.8298 32.6769C27.0144 32.6769 27.1375 32.7384 27.1991 32.923C27.1991 33.1077 27.1375 33.2307 26.9529 33.2923C26.8914 33.2923 25.9683 33.4769 25.2914 34.523C24.3683 35.8769 24.3068 38.0923 24.9221 40.923C24.9837 41.1077 24.8606 41.2307 24.676 41.2923C24.6144 41.2923 24.6144 41.2923 24.6144 41.2923Z"
      fill="#40507F"
    />
    <path
      d="M40.1854 48.6769C40.0007 48.6769 39.8777 48.5538 39.8777 48.3692C39.8777 48.2461 39.9392 36.4307 35.8161 33.4154C35.693 33.2923 35.6315 33.1077 35.7546 32.9846C35.8777 32.8615 36.0623 32.8 36.1854 32.923C40.6161 36.123 40.5546 47.8769 40.4931 48.3692C40.4931 48.4923 40.3084 48.6769 40.1854 48.6769Z"
      fill="#40507F"
    />
    <path
      d="M25.2313 51.877C25.1082 51.877 24.9851 51.8154 24.9236 51.6923C24.8005 51.3847 21.6005 44.0616 24.1851 41.2923C24.6159 40.8616 25.1082 40.6154 25.7236 40.677C28.5543 40.9231 32.4313 46.9539 32.6159 47.2C32.6774 47.3231 32.6774 47.5077 32.4928 47.6308C32.3697 47.6923 32.1851 47.6923 32.062 47.5077C32.0005 47.4462 28.1851 41.477 25.662 41.2923C25.2313 41.2308 24.9236 41.4154 24.6159 41.7231C22.339 44.1847 25.4159 51.3847 25.4774 51.4462C25.539 51.6308 25.4774 51.7539 25.2928 51.877C25.2928 51.8154 25.2313 51.877 25.2313 51.877Z"
      fill="#40507F"
    />
    <path
      d="M33.4157 48H30.9542C30.7696 48 30.6465 47.877 30.6465 47.6923C30.6465 47.5077 30.7696 47.3846 30.9542 47.3846H33.4157C33.6003 47.3846 33.7234 47.5077 33.7234 47.6923C33.7234 47.8154 33.6003 48 33.4157 48Z"
      fill="#40507F"
    />
    <path
      d="M24.8611 52.9231C24.7996 52.9231 24.7381 52.9231 24.6765 52.8616C24.5534 52.8 24.4919 52.5539 24.5534 52.4308L25.8457 50.3385C25.9073 50.2154 26.1534 50.1539 26.2765 50.2154C26.3996 50.277 26.4611 50.5231 26.3996 50.6462L25.1073 52.7385C25.0457 52.8616 24.9227 52.9231 24.8611 52.9231Z"
      fill="#40507F"
    />
    <path
      d="M40.185 50.8923C41.2386 50.8923 42.0927 50.0382 42.0927 48.9846C42.0927 47.931 41.2386 47.0769 40.185 47.0769C39.1314 47.0769 38.2773 47.931 38.2773 48.9846C38.2773 50.0382 39.1314 50.8923 40.185 50.8923Z"
      fill="#40507F"
    />
    <path
      d="M39.4453 48.9846C39.4453 49.4153 39.753 49.723 40.1838 49.723C40.6145 49.723 40.9222 49.4153 40.9222 48.9846C40.9222 48.5538 40.6145 48.2461 40.1838 48.2461C39.753 48.2461 39.4453 48.5538 39.4453 48.9846Z"
      fill="#A37B90"
    />
    <defs>
      <filter
        id="filter0_d_2257_11668"
        x="0"
        y="0"
        width="60"
        height="62.123"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2257_11668" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2257_11668" result="shape" />
      </filter>
    </defs>
  </svg>
);
