import React from "react";
import ReactDatePicker, { DatePickerProps as ReactDatePickerProps } from "react-date-picker";
import styled from "styled-components";
import { colors, componentBorderWidth, fonts, spacing } from "ui-kit";

interface DatePickerProps extends ReactDatePickerProps {
  isInvalid?: boolean;
}

const AugmentedDatePicker = (props: DatePickerProps) => <ReactDatePicker {...props} />;

export const DatePicker = styled(AugmentedDatePicker)`
  width: 100%;
  box-sizing: border-box;

  & .react-date-picker__wrapper {
    border: ${componentBorderWidth} solid ${(props) => (props.isInvalid ? colors.primaryRed : colors.lightGrey)};
    border-radius: ${spacing.s};
    height: ${spacing.xl};
    padding: 0 ${spacing.s};
    font-family: ${fonts.body2.family};
    font-size: ${fonts.body2.size};
    font-weight: ${fonts.body2.weight};
    color: ${colors.darkGrey};
    background-color: ${colors.white70};
  }

  & .react-date-picker__button:hover .react-date-picker__button__icon {
    stroke: ${colors.primaryBlue};
  }

  & .react-date-picker__inputGroup__input {
    background: none;
    outline: none;
  }

  & .react-date-picker__calendar {
    width: 400px;
    max-width: 100vw;
    z-index: 5;
  }

  & .react-calendar {
    width: 100%;
    border-radius: ${spacing.s};
    background-color: ${colors.white};
    border: ${componentBorderWidth} solid ${colors.lightGrey};
    padding: ${spacing.xs} ${spacing.s};
  }

  & .react-calendar button {
    height: ${spacing.xl};
    background: none;
    border: none;
    border-radius: ${spacing.xxs};
    font-family: ${fonts.caption2.family};
    font-size: ${fonts.caption2.size};
    font-weight: ${fonts.caption2.weight};
    cursor: pointer;
  }

  & .react-calendar button:hover {
    background-color: ${colors.lightBlue};
  }

  & .react-calendar button:disabled {
    background: none;
    cursor: auto;
  }

  & .react-calendar .react-calendar__navigation {
    display: flex;
    margin-bottom: ${spacing.xs};
  }

  & .react-calendar .react-calendar__month-view__weekdays {
    margin-bottom: ${spacing.xs};
  }

  & .react-calendar .react-calendar__tile--now {
    color: ${colors.primaryBlue} !important;
    font-weight: ${fonts.caption1.weight};
  }

  & .react-calendar .react-calendar__tile--active {
    background-color: ${colors.primaryBlue} !important;
    color: ${colors.white} !important;
  }

  & .react-calendar abbr[title] {
    text-decoration: none !important;
    font-weight: ${fonts.caption1.weight};
    text-transform: uppercase;
  }
`;
