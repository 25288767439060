import { action, computed, makeObservable, observable } from "mobx";
import { PatientUserRelation, Patient } from "shared";
import { RootStore } from "./root";

export class PatientStore {
  @observable patientUserRelation: PatientUserRelation | undefined;
  @observable patient: Patient | undefined;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @computed
  get showPatientUserRelationField() {
    return this.patientUserRelation === undefined;
  }

  @computed
  get patientIsUser() {
    return !this.patientUserRelation || this.patientUserRelation === "self";
  }

  @computed
  get canShowConfirmationForm() {
    return this.patient !== undefined || this.patientUserRelation !== undefined;
  }

  @action
  setPatientUserRelation(relation: PatientUserRelation | undefined) {
    this.patientUserRelation = relation;
  }

  @action
  setPatient(patient: Patient | undefined) {
    this.patient = patient;
  }
}
