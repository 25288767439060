import styled from "styled-components";
import { Button as EvergreenButton } from "evergreen-ui";
import { colors, componentBorderWidth, focusShadow, fonts, spacing } from "../../styling.constants";

const getApperanceStyle = (appearance: string | undefined) => {
  switch (appearance) {
    case "primary":
      return {
        backgroundColor: colors.primaryBlue,
        color: colors.white,
        borderColor: "transparent",

        disabled: {
          backgroundColor: colors.lightGrey,
          color: colors.darkGrey,
          borderColor: colors.lightGrey,
        },

        hover: {
          backgroundColor: colors.darkBlue,
          color: colors.white,
          borderColor: colors.darkBlue,
        },

        active: {
          backgroundColor: colors.darkBlue,
          color: colors.white,
          borderColor: colors.darkBlue,
        },
      };
    case "minimal":
      return {
        backgroundColor: "transparent",
        color: colors.primaryBlue,
        borderColor: "transparent",

        disabled: {
          backgroundColor: "transparent",
          color: colors.darkGrey,
          borderColor: "transparent",
        },

        hover: {
          backgroundColor: colors.white70,
          color: colors.primaryBlue,
          borderColor: "transparent",
        },

        active: {
          backgroundColor: colors.lightBlue,
          color: colors.darkBlue,
          borderColor: "transparent",
        },
      };
    case "secondary":
      return {
        backgroundColor: colors.white,
        color: colors.darkBlue,
        borderColor: "transparent",

        disabled: {
          backgroundColor: "transparent",
          color: colors.darkGrey,
          borderColor: "transparent",
        },

        hover: {
          backgroundColor: colors.primaryBlue,
          color: colors.white,
          borderColor: "transparent",
        },

        active: {
          backgroundColor: colors.primaryBlue,
          color: colors.white,
          borderColor: "transparent",
        },
      };
    case "link":
      return {
        backgroundColor: "transparent",
        color: colors.primaryBlue,
        borderColor: "transparent",

        disabled: {
          backgroundColor: "transparent",
          color: colors.darkGrey,
          borderColor: "transparent",
        },

        hover: {
          backgroundColor: colors.white70,
          color: colors.primaryBlue,
          borderColor: "transparent",
        },

        active: {
          backgroundColor: colors.lightBlue,
          color: colors.darkBlue,
          borderColor: "transparent",
        },
      };
    default:
      return {
        backgroundColor: "transparent",
        color: colors.primaryBlue,
        borderColor: colors.primaryBlue,

        disabled: {
          backgroundColor: "transparent",
          color: colors.darkGrey,
          borderColor: colors.lightGrey,
        },

        hover: {
          backgroundColor: colors.white70,
          color: colors.darkBlue,
          borderColor: colors.darkBlue,
        },

        active: {
          backgroundColor: colors.lightBlue,
          color: colors.darkBlue,
          borderColor: colors.darkBlue,
        },
      };
  }
};

export const Button = styled(EvergreenButton)`
  font-family: ${fonts.body1.family};
  font-size: ${fonts.body1.size};
  font-weight: ${fonts.body1.weight};
  border-width: ${componentBorderWidth} !important;
  border-radius: ${spacing.s};
  padding: auto ${spacing.s};
  height: ${spacing.xl};
  text-decoration: none;

  background-color: ${props => getApperanceStyle(props.appearance).backgroundColor};
  color: ${props => getApperanceStyle(props.appearance).color};
  border-color: ${props => getApperanceStyle(props.appearance).borderColor};

  &:hover {
    background-color: ${props => getApperanceStyle(props.appearance).hover.backgroundColor} !important;
    color: ${props => getApperanceStyle(props.appearance).hover.color} !important;
    border-color: ${props => getApperanceStyle(props.appearance).hover.borderColor} !important;
    border-width: ${componentBorderWidth} !important;
  }

  &:active {
    background-color: ${props => getApperanceStyle(props.appearance).active.backgroundColor} !important;
    color: ${props => getApperanceStyle(props.appearance).active.color} !important;
    border-color: ${props => getApperanceStyle(props.appearance).active.borderColor} !important;
    border-width: ${componentBorderWidth} !important;
  }

  &:focus,
  &:focus-visible {
    box-shadow: ${focusShadow};
    background-color: ${props => getApperanceStyle(props.appearance).backgroundColor} !important;
    color: ${props => getApperanceStyle(props.appearance).color} !important;
    border-color: ${props => getApperanceStyle(props.appearance).borderColor} !important;
    border-width: ${componentBorderWidth} !important;
  }

  &:disabled {
    background-color: ${props => getApperanceStyle(props.appearance).disabled.backgroundColor} !important;
    color: ${props => getApperanceStyle(props.appearance).disabled.color} !important;
    border-color: ${props => getApperanceStyle(props.appearance).disabled.borderColor}!important;
    border-width: ${componentBorderWidth} !important;
  }
`;
