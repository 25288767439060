import React from "react";
import { Answer, Field } from "shared";

interface FormContextValue {
  currentField: Field;
  isValid: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  answer: Answer | undefined;
  setAnswer: React.Dispatch<React.SetStateAction<Answer | undefined>>;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const formContext = React.createContext<FormContextValue | null>(null);

interface FormProviderProps extends FormContextValue {
  children: React.ReactNode;
}

export const FormProvider = ({
  children,
  currentField,
  isValid,
  setIsValid,
  answer,
  setAnswer,
  onClickNext,
  onClickPrevious,
}: FormProviderProps) => {
  const value = {
    currentField,
    isValid,
    setIsValid,
    answer,
    setAnswer,
    onClickNext,
    onClickPrevious,
  };
  return <formContext.Provider value={value}>{children}</formContext.Provider>;
};

export const useForm = () => {
  const form = React.useContext(formContext);
  if (!form) {
    throw new Error("useForm must be used within a FormProvider.");
  }
  return form;
};

export const useCurrentField = () => {
  const form = useForm();
  return form.currentField;
};

export const useIsValid = (): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const form = useForm();
  return [form.isValid, form.setIsValid];
};

export const useAnswer = (): [Answer | undefined, React.Dispatch<React.SetStateAction<Answer | undefined>>] => {
  const form = useForm();
  return [form.answer, form.setAnswer];
};

export const useNavigate = (): { onClickPrevious: () => void; onClickNext: () => void } => {
  const form = useForm();
  const navigator = { onClickPrevious: form.onClickPrevious, onClickNext: form.onClickNext };
  return navigator;
};
