import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes, { routesArray } from "./utils/routes";

const App = () => {
  return (
    <Routes>
      {routesArray.map(({ path, element }) => (
        <Route path={path} element={element} />
      ))}
      <Route path="/*" element={<Navigate replace to={routes.home.path} />} />
    </Routes>
  );
};

export default App;
