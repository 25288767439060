import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { Button, Container, Hr, spacing, Star } from "ui-kit";
import { Form, Formik, Field, FieldProps, FormikState } from "formik";
import { Gender, gendersArray, MagicTokenResPayload, PatientUserRelation, Province, provincesArray } from "shared";
import { PageWrapper } from "../../components";
import { useFormStore, usePatientStore, useStore } from "../../stores";
import routes from "../../utils/routes";
import UserForm, { limitedPatientUserRelationsArray } from "./user-form";
import PatientForm from "./patient-form";
import { phoneNumberRegex, postalCodeRegex } from "../../utils/regex";
import { StyledCheckbox } from "../../components/styled-formik-fields";

const FormContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xl};

  & > .form-header {
    display: flex;
    flex-direction: column;
    gap: ${spacing.s};
  }

  & > h1 {
    margin-bottom: ${spacing.xs};
  }

  & > label {
    display: block;
    margin-bottom: ${spacing.l};
  }
`;

const AddCaregiverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.m};
  padding: ${spacing.m} 0;
  margin: ${spacing.xl} 0;
  border-top: 1px solid #d8dae5;
  border-bottom: 1px solid #d8dae5;
`;

const TermsAndSubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.l};

  & > div {
    align-self: start;
  }
`;

const FormTitleWrapper = styled.div`
  margin-bottom: ${spacing.l};
`;

const CareGiverFormTitleWrapper = styled(FormTitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type LimitedPatientUserRelation = Omit<PatientUserRelation, "self" | "patient">;

export interface IdentityFormValues {
  patient: {
    firstName: string;
    lastName: string;
    gender?: Gender;
    address?: { street: string; unit: string; city: string; province?: Province; postalCode: string };
    email: string;
    phoneNumber: string;
  };
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    role?: LimitedPatientUserRelation;
    roleDescription?: string;
  };
  terms?: boolean;
}

export type IdentityValues = Omit<IdentityFormValues, "terms">;

const DEFAULT_INITIAL_VALUE = {
  patient: {
    firstName: "",
    lastName: "",
    gender: undefined,
    address: { street: "", unit: "", city: "", province: undefined, postalCode: "" },
    email: "",
    phoneNumber: "",
  },
  user: { firstName: "", lastName: "", email: "", phoneNumber: "", role: undefined, roleDescription: "" },
  terms: false,
};

export const ConfirmationPage = () => {
  const { t } = useTranslation("general");
  const { t: tc } = useTranslation("confirmation");
  const navigate = useNavigate();
  const rootStore = useStore();
  const patientStore = usePatientStore();
  const formStore = useFormStore();

  // TODO: Validate form entries content
  if (!patientStore.canShowConfirmationForm || !formStore.formEntries) navigate(routes.form.path);

  // States

  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [dobFieldTouched, setDobFieldTouched] = useState(false);
  const [patientHasCaregiver, setPatientHasCaregiver] = useState<boolean | undefined>(
    patientStore.patientIsUser ? false : undefined
  );

  // Network

  const { mutateAsync: onSubmit, isLoading } = useMutation(async (values: IdentityFormValues) => {
    if (!dateOfBirth) return;

    const patientPayload = {
      ...values.patient,
      patientUserRelation: patientStore.patientIsUser && !patientHasCaregiver ? "self" : values.user?.role,
      dateOfBirth,
    };
    let userPayload;
    if (patientStore.patientIsUser) {
      userPayload = { ...values.patient };
      delete userPayload.gender;
      delete userPayload.address;
    } else {
      userPayload = { ...values.user };
      // TODO : Maher Remove next line once role descripiton field is added to DB
      delete userPayload?.roleDescription;
    }

    // TODO: Test skippable dob field in identity form creating api error when submmitting patient
    const payload = {
      user: userPayload,
      patient: patientPayload,
      formEntries: formStore.formEntries,
    };

    const magicTokenResponse = await rootStore.executeRequest<MagicTokenResPayload | undefined>({
      method: "post",
      url: `/users`,
      data: payload,
    });

    navigate(routes.assessment.path.replace(":token", magicTokenResponse?.magicToken as string));
  });

  // Rendering

  const patientValidationSchema = Yup.object().shape({
    patient: Yup.object().shape({
      firstName: Yup.string()
        .min(2, t("invalidInputError"))
        .max(50, t("invalidInputError"))
        .required(t("requiredFieldError")),
      lastName: Yup.string()
        .min(2, t("invalidInputError"))
        .max(50, t("invalidInputError"))
        .required(t("requiredFieldError")),
      gender: Yup.string()
        .oneOf([...gendersArray], t("invalidInputError"))
        .required(t("requiredFieldError")),
      email: Yup.string().email(t("invalidEmailError")).required(t("requiredFieldError")),
      phoneNumber: Yup.string()
        .matches(phoneNumberRegex, t("invalidPhoneNumberError"))
        .required(t("requiredFieldError")),
      address: Yup.object().shape({
        street: Yup.string().min(2, t("invalidInputError")).required(t("requiredFieldError")),
        city: Yup.string().min(2, t("invalidInputError")).required(t("requiredFieldError")),
        province: Yup.string()
          .oneOf([...provincesArray], t("invalidInputError"))
          .required(t("requiredFieldError")),
        postalCode: Yup.string()
          .matches(postalCodeRegex, t("invalidPostalCodeError"))
          .min(2, t("invalidInputError"))
          .required(t("requiredFieldError")),
      }),
    }),
  });

  const careGiverValidatonSchema = Yup.object().shape({
    user: Yup.object().shape({
      firstName: Yup.string()
        .min(2, t("invalidInputError"))
        .max(50, t("invalidInputError"))
        .required(t("requiredFieldError")),
      lastName: Yup.string()
        .min(2, t("invalidInputError"))
        .max(50, t("invalidInputError"))
        .required(t("requiredFieldError")),
      email: Yup.string().email(t("invalidEmailError")).required(t("requiredFieldError")),
      phoneNumber: Yup.string().matches(phoneNumberRegex, t("invalidPhoneNumberError")),
      role: Yup.string()
        .oneOf([...limitedPatientUserRelationsArray], t("invalidInputError"))
        .required(t("requiredFieldError")),
    }),
  });

  const fullValidationSchema = patientValidationSchema.clone().concat(careGiverValidatonSchema);

  // By activating the caregiver form, if the form is partially filled, but the form is then removed,
  // the validation errors will remain thuss stopping the user to complete the patient form.
  // By resetting the caregiver form, the user can continue the procedure.
  const resetCareGiverForm = (
    values: IdentityFormValues,
    resetForm: (nextState?: Partial<FormikState<IdentityFormValues>> | undefined) => void
  ) => {
    resetForm({
      values: {
        ...values,
        user: { firstName: "", lastName: "", email: "", phoneNumber: "", role: undefined },
      },
    });
    setPatientHasCaregiver(false);
  };

  return (
    <PageWrapper withNav lightBackground>
      <FormContainer>
        <div className="form-header">
          <h1>{tc("formHeader")}</h1>
          <label>
            <Star />
            {tc("formDescription")}
          </label>
        </div>
        <div>
          <Formik<IdentityFormValues>
            initialValues={DEFAULT_INITIAL_VALUE}
            validationSchema={
              patientHasCaregiver || !patientStore.patientIsUser ? fullValidationSchema : patientValidationSchema
            }
            onSubmit={(values) => onSubmit(values)}>
            {({ values, errors, touched, isValid, submitCount, setFieldValue, resetForm, validateForm }) => {
              const dobError =
                !dateOfBirth && (dobFieldTouched || submitCount > 0) ? t("requiredFieldError") : undefined;
              const formIsValid = isValid && !dobError;
              return (
                <Form>
                  {/* User form */}
                  {!patientStore.patientIsUser && (
                    <>
                      <FormTitleWrapper>
                        <h2>{tc("userFormTitle")}</h2>
                      </FormTitleWrapper>
                      <UserForm errors={errors} touched={touched} /> <Hr />
                    </>
                  )}

                  {/* patient form */}
                  <FormTitleWrapper>
                    <h2>{patientStore.patientIsUser ? tc("userFormTitle") : tc("patientFormTitle")}</h2>
                  </FormTitleWrapper>
                  <PatientForm
                    errors={errors}
                    touched={touched}
                    dobError={dobError}
                    setDobFieldTouched={setDobFieldTouched}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    setFieldValue={setFieldValue}
                    validateForm={validateForm}
                    values={values}
                  />

                  {patientStore.patientIsUser && !patientHasCaregiver ? (
                    <AddCaregiverContainer>
                      {t("addCaregiverQuestion")}
                      <Button onClick={() => setPatientHasCaregiver(true)}>{t("addSomeone")}</Button>
                    </AddCaregiverContainer>
                  ) : (
                    <Hr />
                  )}

                  {/* Care giver's form */}
                  {patientHasCaregiver && (
                    <>
                      <CareGiverFormTitleWrapper>
                        <h2>{tc("careGiverFormTitle")}</h2>
                        <Button appearance="minimal" onClick={() => resetCareGiverForm(values, resetForm)}>
                          {t("remove")}
                        </Button>
                      </CareGiverFormTitleWrapper>
                      <UserForm errors={errors} touched={touched} />
                      <Hr />
                    </>
                  )}

                  {/* Terms&Conditions and submit button section */}
                  <TermsAndSubmitButtonContainer>
                    <div>
                      <Field>
                        {({ field }: FieldProps) => (
                          <StyledCheckbox
                            {...field}
                            name="terms"
                            label={
                              <p>
                                <Trans
                                  i18nKey={"TermsLabel"}
                                  t={t}
                                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                                  components={[<a href={routes.legal.path} target={"_blank"} rel="noreferrer" />]}
                                />
                              </p>
                            }
                            checked={values.terms}
                          />
                        )}
                      </Field>
                    </div>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      appearance="primary"
                      disabled={!formIsValid || !values.terms}>
                      {t("validate")}
                    </Button>
                  </TermsAndSubmitButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </div>
      </FormContainer>
    </PageWrapper>
  );
};
