import { keyframes } from "styled-components";

export const slideFadeInLeftAnimation = keyframes`
    0% { transform: translateX(150px); opacity: 0; }
    100% { transform: translateX(0); opacity: 1 }
`;

export const slideFadeInRightAnimation = keyframes`
    0% { transform: translateX(-150px); opacity: 0; }
    100% { transform: translateX(0); opacity: 1 }
`;

export const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1 }
`;
