import { acceptedLanguageArray, defaultLanguage } from "./constants";
import { AcceptedLanguage } from "./types";

export const mapTextToAcceptedLanguage = (text: string): AcceptedLanguage => {
  if (text.length < 2) return defaultLanguage;

  const substring = text.substring(0, 2);
  const index = acceptedLanguageArray.indexOf(substring as AcceptedLanguage);
  if (index > -1) return acceptedLanguageArray[index];

  return defaultLanguage;
};
