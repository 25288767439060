import { Field, Input } from "ui-kit";
import { AddressSuggestionsResult, gendersArray, provincesArray } from "shared";
import { useTranslation } from "react-i18next";
import { DatePicker, FormikSelect, FormikTextInputField, Row } from "../../components";
import { Field as FormikField, FieldProps, FormikErrors, FormikTouched } from "formik";
import { IdentityFormValues } from ".";
import { Autocomplete } from "evergreen-ui";
import { useEffect, useState } from "react";
import { useStore } from "../../stores";
import { RefetchOptions, useQuery } from "react-query";
import _ from "lodash";

interface PatientFormProps {
  touched: FormikTouched<IdentityFormValues>;
  errors: FormikErrors<IdentityFormValues>;
  dobError: string | undefined;
  setDobFieldTouched: React.Dispatch<React.SetStateAction<boolean>>;
  dateOfBirth: Date | undefined;
  setDateOfBirth: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  validateForm: () => Promise<FormikErrors<IdentityFormValues>>;
  values: IdentityFormValues;
}

const PatientForm = ({
  touched,
  errors,
  dobError,
  setDobFieldTouched,
  dateOfBirth,
  setDateOfBirth,
  setFieldValue,
  validateForm,
  values,
}: PatientFormProps) => {
  const { t, i18n } = useTranslation("general");
  const { language } = i18n;
  const rootStore = useStore();
  const [addressSuggestions, setAddressSuggestions] = useState<Array<AddressSuggestionsResult>>();
  const [streetNameValue, setStreetNameValue] = useState<string | null>();
  const [addressQueryString, setAddressQueryString] = useState<string | null>();
  const STREET_ADDRESS_PATH = "patient.address.street";

  // This useEffect is necessary to trigger the validation of the form when the user types in the address field.
  // When the user fills the address field last, the form is not validated because the user has not yet clicked out of the field.
  useEffect(() => {
    const isFormValid = async () => {
      await validateForm();
    };

    isFormValid();
  }, [values, validateForm]);

  const { refetch } = useQuery(
    ["fetch-location", addressQueryString],
    async () => {
      const data = await rootStore.executeRequest<AddressSuggestionsResult[]>({
        method: "get",
        url: `/location/?searchAddress=${addressQueryString}`,
      });

      if (data) setAddressSuggestions(data);
    },
    { refetchOnWindowFocus: false }
  );

  const setAddressFields = (address: string) => {
    const addressArray = address.split(", ");
    const street = addressArray[0];
    const city = addressArray[1];
    const province = addressArray[2];
    const postalCode = addressArray[3];

    setStreetNameValue(street);

    setFieldValue("patient.address.street", street);
    setFieldValue("patient.address.city", city);
    setFieldValue("patient.address.province", province);
    setFieldValue("patient.address.postalCode", postalCode);
  };

  return (
    <div>
      <FormikTextInputField
        errors={errors}
        touched={touched}
        path={"patient.firstName"}
        label={t("firstName")}
        isRequired
      />

      <FormikTextInputField
        errors={errors}
        touched={touched}
        path={"patient.lastName"}
        label={t("lastName")}
        isRequired
      />

      <Row>
        <Field label={t("dateOfBirth")} isRequired error={dobError}>
          {/* DOB is not part of the formik form because it made the code more complicated than this */}
          <DatePicker
            onCalendarClose={() => setDobFieldTouched(true)}
            locale={language}
            maxDate={new Date()}
            value={dateOfBirth}
            onChange={setDateOfBirth}
            isInvalid={dobError !== undefined}
            dayPlaceholder={t("dayPlaceholder")}
            monthPlaceholder={t("monthPlaceholder")}
            yearPlaceholder={t("yearPlaceholder")}
          />
        </Field>

        <FormikSelect
          OptionsArray={gendersArray}
          t={t}
          errors={errors}
          touched={touched}
          path={"patient.gender"}
          fullWidth={false}
          label={t("gender")}
          isRequired
        />
      </Row>

      <Autocomplete
        title="Addresses"
        onChange={setAddressFields}
        items={
          addressSuggestions !== undefined && addressSuggestions.length > 0
            ? addressSuggestions.map((address: AddressSuggestionsResult) => address.Text)
            : [""]
        }
        allowOtherValues={true}>
        {(props) => {
          const { getInputProps, getRef, inputValue, openMenu } = props;
          return (
            <Row>
              <Field
                label={t("postalAddress")}
                isRequired={true}
                error={_.get(touched, STREET_ADDRESS_PATH) ? _.get(errors, STREET_ADDRESS_PATH) : undefined}>
                <FormikField name={STREET_ADDRESS_PATH}>
                  {(field: FieldProps) => (
                    <Input
                      {...field}
                      placeholder={t("street")}
                      ref={getRef}
                      isInvalid={
                        _.get(touched, STREET_ADDRESS_PATH) && _.get(errors, STREET_ADDRESS_PATH) !== undefined
                      }
                      {...getInputProps({
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(STREET_ADDRESS_PATH, e.target.value);
                          setAddressQueryString(e.target.value);
                          refetch(e.target.value as RefetchOptions);
                          setStreetNameValue(null);
                        },
                        onFocus: () => {
                          openMenu();
                        },
                        value: streetNameValue ? streetNameValue : inputValue,
                      })}
                    />
                  )}
                </FormikField>
              </Field>
            </Row>
          );
        }}
      </Autocomplete>
      <FormikTextInputField errors={errors} touched={touched} path={"patient.address.unit"} placeHolder={t("unit")} />
      <FormikTextInputField
        errors={errors}
        touched={touched}
        path={"patient.address.city"}
        placeHolder={t("city")}
        label={t("city")}
        isRequired
      />
      <FormikSelect
        OptionsArray={provincesArray}
        t={t}
        errors={errors}
        touched={touched}
        path={"patient.address.province"}
        placeHolder={t("province")}
        label={t("province")}
        isRequired
      />
      <FormikTextInputField
        errors={errors}
        touched={touched}
        path={"patient.address.postalCode"}
        placeHolder={t("postalCode")}
        label={t("postalCode")}
        isRequired
      />

      <FormikTextInputField
        errors={errors}
        touched={touched}
        path={"patient.email"}
        label={t("email")}
        type="email"
        isRequired
      />

      <FormikTextInputField
        errors={errors}
        touched={touched}
        path={"patient.phoneNumber"}
        label={t("phoneNumber")}
        isRequired
      />
    </div>
  );
};

export default PatientForm;
