import React from "react";

export const StarIcon = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    {...props}
    style={{ position: "absolute", width: "21px", height: "22.5px", left: "40px", top: "0px" }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0.519277V0.5H11C11 0.640072 11.0009 0.788155 11.0019 0.943346C11.0034 1.18518 11.005 1.44426 11.0034 1.71718C11.0124 2.76868 11.0445 3.76229 11.1602 4.68798C11.3192 5.96013 11.6322 7.06389 12.2305 7.97567C13.4029 9.76213 15.8074 11 21 11V12.5C15.8074 12.5 13.4029 13.7379 12.2305 15.5243C11.6322 16.4361 11.3192 17.5399 11.1602 18.812C11.0445 19.7377 11.0124 20.7313 11.0034 21.7828C11.005 22.0557 11.0034 22.3148 11.0019 22.5567C11.0009 22.7118 11 22.8599 11 23H10V22.9807C10 22.6152 10 22.2506 10.0025 21.8893C9.99569 20.225 9.88679 18.1858 8.93515 16.4253C7.79672 14.3192 5.37366 12.5 0 12.5V11C5.37366 11 7.79672 9.18084 8.93515 7.07474C9.88679 5.3142 9.99569 3.27495 10.0025 1.61071C10 1.24937 10 0.884793 10 0.519277Z"
      fill="#429777"
    />
  </svg>
);
