import styled from "styled-components";
import { spacing } from "../../styling.constants";

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  padding: ${spacing.l} 0;
  margin: auto;
  box-sizing: border-box;
  overflow-x: visible;
`;
