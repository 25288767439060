import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, Menu, Popover, Position } from "evergreen-ui";
import { AcceptedLanguage, acceptedLanguageArray, mapTextToAcceptedLanguage } from "shared";
import { Button } from "ui-kit";

const LanguageButton = styled(Button)`
  text-transform: uppercase;
  height: 35px;
  padding: 0 10px;
`;

const LanguagePicker = () => {
  const { t, i18n } = useTranslation("general");
  const { changeLanguage, language } = i18n;
  const lang = mapTextToAcceptedLanguage(language);

  const renderPopoverContent = ({ close }: { close: () => void }) => {
    const onSelectLanguage = (language: AcceptedLanguage) => {
      changeLanguage(language);
      close();
    };

    // TODO: Style the options - I haven't been able to style it using the library's theming - pretty annoying - maybe we create a custom menu
    return (
      <Menu>
        <Menu.OptionsGroup
          options={acceptedLanguageArray.map((lang) => ({ label: t(lang), value: lang }))}
          selected={lang}
          onChange={onSelectLanguage}
        />
      </Menu>
    );
  };

  const highlightSelectedLanguage = (
    languageOption: AcceptedLanguage,
    languageIndex: number,
    languageArrayLength: number
  ) => (
    <>
      <span style={{ fontWeight: languageOption === language ? "bold" : "normal" }}>{languageOption}</span>
      {languageIndex !== languageArrayLength - 1 && <span style={{ fontWeight: "normal" }}>&nbsp;|&nbsp;</span>}
    </>
  );

  return (
    <Popover position={Position.BOTTOM_LEFT} content={renderPopoverContent}>
      <LanguageButton appearance="minimal" iconAfter={<ChevronDownIcon />}>
        {acceptedLanguageArray.map((language, index, array) =>
          highlightSelectedLanguage(language, index, array.length)
        )}
      </LanguageButton>
    </Popover>
  );
};

export default LanguagePicker;
