import { action, computed, flow, makeObservable, observable } from "mobx";
import { Answer, Form, FormEntry } from "shared";
import { buildRequiredFieldsArray } from "shared";
import { RootStore } from "./root";

export class FormStore {
  @observable forms: Form[] | null = null;
  @observable formEntries: FormEntry[] | null = null;
  @observable currentFormIndex: number = 0;
  @observable currentFieldIndex: number = 0;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  fetchForms = flow(function* (this: FormStore) {
    const forms = yield this.rootStore.executeRequest<Form[]>({
      method: "get",
      url: `/forms`,
    });

    this.forms = forms;
    this.formEntries = this.forms!.map((form) => ({
      formKey: form.key,
      startAt: new Date(),
      endAt: new Date(),
      answers: [],
    }));
  });

  @action
  decrementFieldIndex = () => {
    if (!this.forms) return;

    if (this.isInitialField) return;

    if (this.isFirstFieldInForm) {
      this.currentFormIndex = this.currentFormIndex - 1;
      this.currentFieldIndex = this.requiredFields.length - 1;
      return;
    }

    this.currentFieldIndex = this.currentFieldIndex - 1;
  };

  @action
  incrementFieldIndex = () => {
    if (!this.forms) return;

    if (this.isFinalField) {
      this.currentFormEntry.endAt = new Date();
      return;
    }

    if (this.isLastFieldInForm) {
      this.currentFormEntry.endAt = new Date();
      this.currentFormIndex = this.currentFormIndex + 1;
      this.currentFieldIndex = 0;
      this.currentFormEntry.startAt = new Date();
      return;
    }

    this.currentFieldIndex = this.currentFieldIndex + 1;
  };

  @action
  saveAnswer = (answer: Answer) => {
    const existingAnswerIndex = this.currentFormEntry.answers.findIndex(
      (answer: Answer) => answer.fieldKey === this.currentField.key
    );
    if (existingAnswerIndex > -1) {
      this.currentFormEntry.answers.splice(existingAnswerIndex, 1);
    }
    this.currentFormEntry.answers.push(answer);
  };

  @action
  resetForm = () => {
    this.currentFormIndex = 0;
    this.currentFieldIndex = 0;
    this.forms = [];
    this.formEntries = this.forms!.map((form) => ({
      formKey: form.key,
      startAt: new Date(),
      endAt: new Date(),
      answers: [],
    }));
  };

  @computed
  get currentField() {
    return this.requiredFields[this.currentFieldIndex];
  }

  @computed
  get currentForm() {
    return this.forms![this.currentFormIndex];
  }

  @computed
  get currentFormEntry() {
    return this.formEntries![this.currentFormIndex];
  }

  @computed
  get currentAnswer() {
    return this.currentFormEntry.answers.find(({ fieldKey }: Answer) => fieldKey === this.currentField.key);
  }

  @computed
  get isFirstFieldInForm() {
    return this.currentFieldIndex === 0;
  }

  @computed
  get isLastFieldInForm() {
    return this.currentFieldIndex === this.requiredFields.length - 1;
  }

  @computed
  get isInitialField() {
    return this.currentFormIndex === 0 && this.currentFieldIndex === 0;
  }

  @computed
  get isFinalField() {
    return (
      this.currentFormIndex === this.forms!.length - 1 && this.currentFieldIndex === this.requiredFields.length - 1
    );
  }

  @computed
  get requiredFields() {
    return buildRequiredFieldsArray(this.currentForm.fields, this.currentFormEntry.answers);
  }
}
