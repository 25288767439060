import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "ui-kit";
import { PageWrapper } from "../../components";

export const LegalPage = () => {
  const { t } = useTranslation("legal");

  return (
    <PageWrapper withNav lightBackground>
      <Container>
        <>
          <h1>{t("terms_header")}</h1>
          <br />
          <p dangerouslySetInnerHTML={{ __html: t("terms_text") }} />
          <br />
          <br />
          <h1>{t("privacy_header")}</h1>
          <br />
          <p dangerouslySetInnerHTML={{ __html: t("privacy_text") }} />
          <br />
        </>
      </Container>
    </PageWrapper>
  );
};
