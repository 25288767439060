import React from "react";

export const HomeIllustration = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg {...props} width="412" height="408" viewBox="0 0 412 408" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{ mixBlendMode: "multiply" }} opacity="0.21">
      <path
        d="M304.442 407.629C384.214 407.629 448.883 403.433 448.883 398.258C448.883 393.083 384.214 388.888 304.442 388.888C224.669 388.888 160 393.083 160 398.258C160 403.433 224.669 407.629 304.442 407.629Z"
        fill="#6C6C6C"
      />
    </g>
    <path
      d="M221.793 310.159H12.2979C6.06667 310.159 0.957031 305.26 0.957031 299.285V28.1335C0.957031 22.1584 6.06667 17.2588 12.2979 17.2588H221.793C228.024 17.2588 233.134 22.1584 233.134 28.1335V299.285C233.134 305.26 228.024 310.159 221.793 310.159Z"
      fill="#9DB5FF"
    />
    <path
      d="M20.6996 311.159H218.06C224.481 311.159 229.744 305.911 229.744 299.5V33.7274C229.744 27.3163 224.481 22.0684 218.06 22.0684H20.6996C14.2792 22.0684 9.01562 27.3163 9.01562 33.7274V299.5C9.01562 305.911 14.2792 311.159 20.6996 311.159Z"
      fill="white"
      stroke="#9DB5FF"
      stroke-width="2"
    />
    <path
      d="M234.705 51.9373C248.806 51.9373 260.237 40.5331 260.237 26.4653C260.237 12.3976 248.806 0.993408 234.705 0.993408C220.605 0.993408 209.174 12.3976 209.174 26.4653C209.174 40.5331 220.605 51.9373 234.705 51.9373Z"
      fill="#1D6A4E"
    />
    <path
      d="M231.797 51.9373C245.898 51.9373 257.329 40.5331 257.329 26.4653C257.329 12.3976 245.898 0.993408 231.797 0.993408C217.697 0.993408 206.266 12.3976 206.266 26.4653C206.266 40.5331 217.697 51.9373 231.797 51.9373Z"
      fill="#31A57B"
    />
    <path
      d="M233.089 42.0224H230.423C229.372 42.0224 228.564 41.2164 228.564 40.1685V12.6814C228.564 11.6335 229.372 10.8274 230.423 10.8274H233.089C234.139 10.8274 234.947 11.6335 234.947 12.6814V40.2491C234.947 41.2164 234.139 42.0224 233.089 42.0224Z"
      fill="white"
    />
    <path
      d="M247.39 25.0952V27.7552C247.39 28.8031 246.582 29.6092 245.532 29.6092H217.899C216.849 29.6092 216.041 28.8031 216.041 27.7552V25.0952C216.041 24.0473 216.849 23.2412 217.899 23.2412H245.532C246.582 23.2412 247.39 24.0473 247.39 25.0952Z"
      fill="white"
    />
    <path
      d="M151.725 52.3516H84.6862C77.5244 52.3516 71.7715 46.6121 71.7715 39.4671V24.357C71.7715 23.3028 72.5933 22.4829 73.65 22.4829C74.7067 22.4829 75.5285 23.3028 75.5285 24.357V39.3499C75.5285 44.3866 79.6377 48.3691 84.5688 48.3691H151.608C156.657 48.3691 160.648 44.2695 160.648 39.3499V24.357C160.648 23.3028 161.47 22.4829 162.527 22.4829C163.584 22.4829 164.405 23.3028 164.405 24.357V39.3499C164.64 46.495 158.887 52.3516 151.725 52.3516Z"
      fill="#9DB5FF"
    />
    <path
      d="M153.396 87.7529C153.396 90.2127 153.044 92.6724 152.457 94.898C151.048 100.286 147.996 104.971 144.004 108.602C143.769 108.837 143.417 109.071 143.182 109.305C142.125 110.125 141.069 110.945 140.012 111.648C139.542 111.999 139.073 112.234 138.486 112.468C138.368 112.585 138.251 112.585 138.016 112.702C134.259 114.576 129.915 115.747 125.336 115.747C123.81 115.747 122.284 115.63 120.757 115.396C119.701 115.279 118.644 115.045 117.587 114.693C114.183 113.756 111.13 112.116 108.312 110.125C107.608 109.657 107.021 109.071 106.434 108.602C105.377 107.665 104.438 106.611 103.616 105.557C103.264 105.206 103.029 104.854 102.794 104.386C99.3894 99.7004 97.2761 93.9609 97.2761 87.7529C97.2761 72.2914 109.839 59.7583 125.336 59.7583C140.834 59.7583 153.396 72.2914 153.396 87.7529Z"
      fill="#F9DCDC"
    />
    <path
      d="M132.628 80.8149C132.628 80.8149 137.442 75.3097 135.563 72.9671C133.685 70.6244 130.632 72.8499 130.632 72.8499C130.632 72.8499 130.515 70.1559 127.579 70.3902C124.644 70.6244 122.766 73.6699 122.766 73.6699C122.766 73.6699 120.183 70.9758 118.422 73.5527C116.543 76.1297 117.6 80.3464 117.6 80.3464C117.6 80.3464 115.604 79.995 116.074 82.4548C116.543 84.9146 119.713 87.4915 119.713 87.4915L132.628 80.8149Z"
      fill="#888787"
    />
    <path
      d="M122.295 89.3655L122.06 95.6906C122.06 95.6906 123.469 97.0962 127.226 97.5647C130.044 97.9161 130.514 96.6277 130.514 96.6277L130.396 94.6364L130.161 91.4739L123.469 89.7169L122.295 89.3655Z"
      fill="#F48462"
    />
    <path
      d="M123.352 89.7168C123.352 89.8339 123.47 90.0682 123.47 90.3025C124.761 93.5822 128.636 94.4021 130.279 94.6364L130.044 91.4738L123.352 89.7168Z"
      fill="black"
    />
    <path
      d="M132.157 76.9495C132.157 76.9495 135.092 85.1487 133.566 89.4826C132.04 93.8165 128.048 92.8795 125.582 91.9424C123.234 90.8882 122.177 89.3655 122.177 89.3655C122.177 89.3655 119.829 89.9511 118.42 87.8428C117.012 85.6173 118.068 83.2746 119.477 82.8061C120.886 82.3375 122.295 83.3917 122.295 83.3917L121.238 81.0491C121.356 80.932 127.109 69.453 132.157 76.9495Z"
      fill="#F48462"
    />
    <path
      d="M122.06 86.6715C122.177 86.6715 122.177 86.6715 122.295 86.5544C122.412 86.4373 122.412 86.3201 122.295 86.203L119.477 83.9775C119.36 83.8604 119.242 83.8604 119.125 83.9775C119.007 84.0946 119.007 84.2118 119.125 84.3289L121.825 86.6715C121.943 86.6715 121.943 86.6715 122.06 86.6715Z"
      fill="black"
    />
    <path
      d="M119.361 86.5543L121.357 86.2029C121.474 86.2029 121.592 86.0858 121.592 85.9686C121.592 85.8515 121.474 85.7344 121.357 85.7344L119.478 86.0858C119.361 86.0858 119.244 86.2029 119.244 86.32C119.126 86.4372 119.244 86.5543 119.361 86.5543Z"
      fill="black"
    />
    <path
      d="M126.756 80.4634C126.873 80.4634 126.991 80.4634 126.991 80.3463C127.46 79.0579 128.634 79.2921 128.634 79.2921C128.752 79.2921 128.869 79.2921 128.869 79.0579C128.869 78.9407 128.869 78.8236 128.634 78.8236C128.047 78.7065 126.991 78.8236 126.404 80.1121C126.521 80.2292 126.638 80.3463 126.756 80.4634Z"
      fill="black"
    />
    <path
      d="M132.863 80.1121C132.98 80.1121 132.98 80.1121 132.98 79.995C133.098 79.8779 133.098 79.7607 132.98 79.6436C132.745 79.4093 132.041 78.8237 131.689 79.0579C131.571 79.1751 131.571 79.2922 131.689 79.4093C131.806 79.5265 131.806 79.5265 131.924 79.4093C131.924 79.4093 132.158 79.5265 132.628 79.8779C132.746 79.995 132.863 80.1121 132.863 80.1121Z"
      fill="black"
    />
    <path
      d="M131.571 86.0859C131.688 86.0859 131.688 86.0859 131.806 85.9688C132.862 84.6803 130.984 82.5719 130.866 82.4548C130.749 82.3377 130.632 82.3377 130.514 82.4548C130.397 82.5719 130.397 82.6891 130.514 82.8062C130.984 83.3918 132.041 84.9146 131.454 85.7345C131.336 85.8516 131.336 85.9688 131.454 86.0859C131.454 86.0859 131.453 86.0859 131.571 86.0859Z"
      fill="black"
    />
    <path
      d="M132.744 83.1575C132.861 83.1575 132.979 83.0404 132.979 82.9232L132.626 81.4005C132.626 81.2834 132.509 81.1662 132.391 81.2834C132.274 81.2834 132.157 81.4005 132.274 81.5176L132.626 83.0404C132.509 83.0404 132.626 83.1575 132.744 83.1575Z"
      fill="black"
    />
    <path
      d="M128.636 83.0403C128.754 83.0403 128.871 82.9232 128.871 82.806L128.519 81.2833C128.519 81.1662 128.401 81.0491 128.284 81.1662C128.167 81.1662 128.049 81.2833 128.167 81.4005L128.519 82.9232C128.401 82.9232 128.519 83.0403 128.636 83.0403Z"
      fill="black"
    />
    <path
      d="M132.745 78.8235C132.745 78.8235 132.158 77.5351 130.514 77.418C128.87 77.3008 127.931 78.355 127.931 78.355C127.931 78.355 128.518 81.0491 126.287 82.3375C124.056 83.626 122.413 83.1574 122.413 83.1574L120.886 82.5718L119.83 80.2291L124.643 75.5438L130.631 72.7327L133.566 75.5438L132.745 78.8235Z"
      fill="#888787"
    />
    <path
      d="M129.81 88.5456C129.928 88.5456 130.045 88.4284 130.045 88.3113C130.045 88.1942 129.928 88.077 129.81 88.077C129.81 88.077 128.519 88.077 127.932 86.9057C127.932 86.7886 127.697 86.7886 127.579 86.7886C127.462 86.7886 127.462 87.0228 127.462 87.14C128.166 88.4284 129.575 88.5456 129.81 88.5456Z"
      fill="black"
    />
    <path
      d="M144.367 110.683C143.311 111.503 142.254 112.323 141.197 113.026C140.728 113.378 140.258 113.612 139.671 113.846C139.554 113.963 139.436 113.963 139.201 114.08C135.444 115.954 131.1 117.126 126.521 117.126C124.995 117.126 123.469 117.009 121.942 116.774C120.886 116.657 119.829 116.423 118.773 116.072C115.368 115.135 112.315 113.495 109.497 111.503C108.793 111.035 108.206 110.449 107.619 109.981C106.562 109.044 105.623 107.989 104.801 106.935C104.449 106.584 104.214 106.232 103.979 105.764C105.858 102.953 109.028 99.3217 113.724 97.4476C115.016 96.979 116.424 96.5105 117.951 96.3934C118.068 96.3934 118.068 96.3934 118.185 96.3934C118.303 96.3934 118.42 96.3934 118.538 96.3934C118.655 96.3934 118.773 96.3934 118.89 96.3934C119.125 96.3934 119.242 96.3934 119.36 96.3934C119.712 96.3934 120.181 96.2762 120.534 96.2762C130.983 95.9248 136.501 100.024 136.853 100.259C136.853 100.259 136.853 100.259 136.971 100.376C137.088 100.493 137.323 100.727 137.675 101.196C139.201 102.484 142.019 106.115 144.367 110.683Z"
      fill="#40507F"
    />
    <path
      d="M139.202 114.08C135.445 115.954 131.101 117.126 126.522 117.126C124.995 117.126 123.469 117.009 121.943 116.774C120.886 116.657 119.83 116.423 118.773 116.072C115.368 115.135 112.316 113.495 109.498 111.503C110.672 104.358 111.963 98.5017 112.903 97.7989C113.137 97.6818 113.372 97.5647 113.607 97.4475C114.898 96.8619 116.542 96.5105 117.834 96.3934C117.951 96.3934 117.951 96.3934 118.068 96.3934C118.186 96.3934 118.303 96.3934 118.421 96.3934C118.538 96.3934 118.656 96.3934 118.773 96.3934C119.008 96.3934 119.125 96.3934 119.243 96.3934C119.947 96.3934 120.417 96.3934 120.417 96.3934C120.417 95.9248 120.769 92.8794 121.591 92.8794C122.53 92.9965 124.643 94.4021 126.991 94.6364C129.34 94.8706 130.514 94.6364 130.983 94.6364C131.453 94.6364 131.57 97.0962 131.57 97.0962C131.57 97.0962 134.623 98.1503 136.854 100.259C136.854 100.259 136.854 100.259 136.971 100.376C137.088 100.493 137.323 100.727 137.675 101.196C137.91 101.547 138.145 101.899 138.38 102.25C139.202 103.187 139.319 108.224 139.202 114.08Z"
      fill="#40507F"
    />
    <path
      d="M125.582 97.3304C125.934 97.3304 126.286 97.3304 126.639 97.2133C126.756 97.2133 126.873 97.0961 126.756 96.979C126.756 96.8619 126.639 96.7447 126.521 96.8619C124.525 97.3304 120.768 95.9248 120.768 95.9248C120.651 95.9248 120.533 95.9248 120.416 96.0419C120.416 96.1591 120.416 96.2762 120.533 96.3933C120.768 96.3933 123.469 97.3304 125.582 97.3304Z"
      fill="#F7B369"
    />
    <path
      d="M58.7994 151.257L58.8004 151.234V151.211V133.407C58.8004 130.259 56.2204 127.839 53.2215 127.839H35.3757C32.2261 127.839 29.7969 130.41 29.7969 133.407V151.211C29.7969 154.359 32.3769 156.779 35.3757 156.779H53.2215C56.261 156.779 58.6595 154.327 58.7994 151.257Z"
      fill="white"
      stroke="#C5EADC"
      stroke-width="2"
    />
    <path
      d="M58.7993 196.941L58.8004 196.917V196.893V179.089C58.8004 175.941 56.2204 173.521 53.2215 173.521H35.3757C32.2261 173.521 29.7969 176.091 29.7969 179.089V196.893C29.7969 200.041 32.3769 202.461 35.3757 202.461H53.2215C56.2779 202.461 58.6592 199.875 58.7993 196.941Z"
      fill="white"
      stroke="#C5EADC"
      stroke-width="2"
    />
    <path
      d="M58.7994 242.503L58.8004 242.48V242.457V224.653C58.8004 221.505 56.2204 219.085 53.2215 219.085H35.3757C32.2261 219.085 29.7969 221.656 29.7969 224.653V242.457C29.7969 245.605 32.3769 248.025 35.3757 248.025H53.2215C56.261 248.025 58.6595 245.573 58.7994 242.503Z"
      fill="white"
      stroke="#EFF2F1"
      stroke-width="2"
    />
    <path
      d="M58.7994 288.067L58.8004 288.045V288.022V270.218C58.8004 267.07 56.2204 264.65 53.2215 264.65H35.3757C32.2261 264.65 29.7969 267.22 29.7969 270.218V288.022C29.7969 291.17 32.3769 293.59 35.3757 293.59H53.2215C56.261 293.59 58.6595 291.138 58.7994 288.067Z"
      fill="white"
      stroke="#EFF2F1"
      stroke-width="2"
    />
    <path
      d="M43.3096 150.452C42.8162 150.452 42.3227 150.255 42.0266 149.861L34.9209 140.901C34.3288 140.212 34.5262 139.227 35.217 138.636C35.9078 138.046 36.8947 138.243 37.4869 138.932L43.3096 146.218L51.1061 135.092C51.5996 134.403 52.5865 134.206 53.376 134.698C54.0669 135.19 54.2642 136.175 53.7708 136.963L44.6913 149.861C44.2965 150.156 43.8031 150.452 43.3096 150.452Z"
      fill="#31A57B"
    />
    <path
      d="M43.3096 196.76C42.8162 196.76 42.3227 196.563 42.0266 196.169L34.9209 187.21C34.3288 186.52 34.5262 185.536 35.217 184.945C35.9078 184.354 36.8947 184.551 37.4869 185.24L43.3096 192.526L51.1061 181.4C51.5996 180.711 52.5865 180.514 53.376 181.007C54.0669 181.499 54.2642 182.484 53.7708 183.271L44.6913 196.169C44.2965 196.465 43.8031 196.662 43.3096 196.76Z"
      fill="#31A57B"
    />
    <path
      d="M165.112 134.579H70.717C70.0126 134.579 69.543 133.993 69.543 133.407V132.939C69.543 132.236 70.13 131.767 70.717 131.767H165.112C165.816 131.767 166.286 132.353 166.286 132.939V133.407C166.403 134.11 165.816 134.579 165.112 134.579Z"
      fill="#D9DEEF"
    />
    <path
      d="M181.782 143.832H70.9497C70.2452 143.832 69.6582 143.246 69.6582 142.543V142.309C69.6582 141.606 70.2452 141.021 70.9497 141.021H181.782C182.486 141.021 183.073 141.606 183.073 142.309V142.543C183.073 143.246 182.486 143.832 181.782 143.832Z"
      fill="#D9DEEF"
    />
    <path
      d="M205.146 152.968H71.0671C70.2452 152.968 69.6582 152.383 69.6582 151.563C69.6582 150.743 70.2452 150.157 71.0671 150.157H205.146C205.967 150.157 206.554 150.743 206.554 151.563C206.554 152.383 205.85 152.968 205.146 152.968Z"
      fill="#D9DEEF"
    />
    <path
      d="M165.112 271.389H70.717C70.0126 271.389 69.543 270.803 69.543 270.217V269.749C69.543 269.046 70.13 268.578 70.717 268.578H165.112C165.816 268.578 166.286 269.163 166.286 269.749V270.217C166.403 270.92 165.816 271.389 165.112 271.389Z"
      fill="#D9DEEF"
    />
    <path
      d="M181.782 280.525H70.9497C70.2452 280.525 69.6582 279.94 69.6582 279.237V279.003C69.6582 278.3 70.2452 277.714 70.9497 277.714H181.782C182.486 277.714 183.073 278.3 183.073 279.003V279.237C183.073 279.94 182.486 280.525 181.782 280.525Z"
      fill="#D9DEEF"
    />
    <path
      d="M205.146 289.778H71.0671C70.2452 289.778 69.6582 289.193 69.6582 288.373C69.6582 287.553 70.2452 286.967 71.0671 286.967H205.146C205.967 286.967 206.554 287.553 206.554 288.373C206.554 289.076 205.85 289.778 205.146 289.778Z"
      fill="#D9DEEF"
    />
    <path
      d="M165.112 180.26H70.717C70.0126 180.26 69.543 179.674 69.543 179.088V178.62C69.543 177.917 70.13 177.448 70.717 177.448H165.112C165.816 177.448 166.286 178.034 166.286 178.62V179.088C166.403 179.674 165.816 180.26 165.112 180.26Z"
      fill="#D9DEEF"
    />
    <path
      d="M181.782 189.397H70.9497C70.2452 189.397 69.6582 188.811 69.6582 188.108V187.874C69.6582 187.171 70.2452 186.585 70.9497 186.585H181.782C182.486 186.585 183.073 187.171 183.073 187.874V188.108C183.073 188.811 182.486 189.397 181.782 189.397Z"
      fill="#D9DEEF"
    />
    <path
      d="M166.051 198.533H70.717C70.0126 198.533 69.543 197.947 69.543 197.361V196.893C69.543 196.19 70.13 195.721 70.717 195.721H166.051C166.756 195.721 167.225 196.307 167.225 196.893V197.361C167.343 198.064 166.756 198.533 166.051 198.533Z"
      fill="#D9DEEF"
    />
    <path
      d="M193.877 225.824H70.9518C70.2474 225.824 69.543 225.239 69.543 224.419V224.302C69.543 223.599 70.13 222.896 70.9518 222.896H193.877C194.581 222.896 195.286 223.482 195.286 224.302V224.419C195.168 225.239 194.581 225.824 193.877 225.824Z"
      fill="#D9DEEF"
    />
    <path
      d="M170.863 234.961H70.8323C70.1278 234.961 69.6582 234.375 69.6582 233.789V233.438C69.6582 232.735 70.2452 232.267 70.8323 232.267H170.863C171.567 232.267 172.037 232.852 172.037 233.438V233.789C172.037 234.375 171.567 234.961 170.863 234.961Z"
      fill="#D9DEEF"
    />
    <path
      d="M186.948 244.097H70.9497C70.2452 244.097 69.6582 243.511 69.6582 242.809V242.574C69.6582 241.872 70.2452 241.286 70.9497 241.286H186.948C187.652 241.286 188.239 241.872 188.239 242.574V242.809C188.239 243.511 187.652 244.097 186.948 244.097Z"
      fill="#D9DEEF"
    />
    <path d="M216.405 366.852H220.396L226.736 352.796L213.117 359.824L216.405 366.852Z" fill="#F29E4C" />
    <path
      d="M280.626 238.943C280.508 237.889 279.569 237.069 278.278 236.835C274.755 236.484 271.585 236.366 269.237 234.258C266.42 231.798 264.658 228.402 264.658 224.653V219.968C264.658 219.031 263.719 218.328 262.663 218.328H177.777C176.721 218.328 175.782 219.031 175.782 219.968V224.653C175.782 231.447 169.794 237.186 162.162 238.006C160.871 238.123 159.932 239.061 159.932 240.232C159.932 241.872 159.932 244.331 160.049 247.611C160.871 269.398 168.15 324.684 216.991 365.329C218.048 366.266 220.514 367.789 223.918 365.329H224.036C267.828 328.901 278.16 280.642 280.508 255.342C281.095 248.314 280.861 241.637 280.626 238.943Z"
      fill="#F29E4C"
    />
    <path
      d="M276.633 240.232C276.516 239.178 275.577 238.358 274.285 238.123C270.763 237.772 267.593 236.366 265.245 234.258C262.427 231.798 260.666 228.402 260.666 224.653V219.968C260.666 219.031 259.727 218.328 258.67 218.328H173.902C172.846 218.328 171.907 219.031 171.907 219.968V224.653C171.907 231.447 165.919 237.186 158.287 238.006C156.996 238.123 156.057 239.061 156.057 240.232C156.057 241.872 156.057 244.331 156.174 247.611C156.996 269.398 164.275 324.684 213.116 365.329C214.173 366.266 216.639 367.789 220.043 365.329H220.161C263.953 328.901 274.285 280.642 276.633 255.342C277.103 248.314 276.868 242.926 276.633 240.232Z"
      fill="#FFB164"
    />
    <path
      d="M265.362 250.071C265.245 249.134 264.54 248.548 263.484 248.431C260.666 248.08 258.083 247.026 256.205 245.269C253.856 243.277 252.448 240.583 252.448 237.538V233.79C252.448 233.087 251.743 232.384 250.804 232.384H182.004C181.064 232.384 180.36 232.97 180.36 233.79V237.538C180.36 243.043 175.546 247.728 169.324 248.431C168.267 248.548 167.562 249.368 167.562 250.188C167.562 251.477 167.563 253.468 167.68 256.162C168.384 273.849 174.255 318.71 213.938 351.742C214.76 352.445 216.756 353.733 219.574 351.742C219.574 351.742 219.574 351.742 219.691 351.742C255.148 322.107 263.601 282.985 265.48 262.487C265.715 256.63 265.48 252.297 265.362 250.071Z"
      fill="white"
    />
    <path
      d="M239.532 258.504C232.957 255.576 223.8 260.496 216.403 269.983C209.006 260.496 199.849 255.693 193.274 258.504C184.82 262.253 183.999 277.831 191.63 293.293C196.913 304.069 205.014 312.034 212.294 314.377C213.585 314.962 214.994 315.196 216.403 315.196C217.812 315.196 219.221 314.962 220.512 314.377C227.791 312.034 235.775 304.069 241.176 293.293C248.807 277.831 248.103 262.253 239.532 258.504Z"
      fill="#ED5C87"
    />
    <path
      d="M211.708 306.177C211.356 306.177 211.121 305.943 211.121 305.709L205.134 275.606L200.437 292.824C200.32 293.059 200.085 293.293 199.85 293.293H186.466C186.114 293.293 185.879 293.059 185.879 292.707C185.879 292.356 186.114 292.121 186.466 292.121H199.381L204.664 272.678C204.781 272.443 205.016 272.209 205.251 272.209C205.603 272.209 205.838 272.443 205.838 272.678L211.708 301.961L215.113 280.643C215.113 280.408 215.348 280.174 215.7 280.057C215.935 280.057 216.287 280.174 216.405 280.408L221.218 292.004H248.691C249.044 292.004 249.279 292.239 249.279 292.59C249.279 292.941 249.044 293.176 248.691 293.176H220.749C220.514 293.176 220.279 293.059 220.162 292.824L216.052 282.985L212.413 305.592C212.295 305.943 211.943 306.177 211.708 306.177Z"
      fill="white"
    />
    <path
      d="M335.399 343.66L339.508 355.607C339.508 355.607 333.755 358.301 333.168 361.347C332.581 364.392 335.869 366.383 335.869 366.383L325.654 378.916L357.589 374.934L354.654 343.542H335.399V343.66Z"
      fill="#4059AD"
    />
    <path
      d="M364.866 111.386C364.866 111.386 375.903 112.792 379.894 123.334C383.886 133.876 381.538 147.58 381.538 147.58L361.109 142.895L362.049 124.037L364.866 111.386Z"
      fill="#101840"
    />
    <path
      d="M355.475 131.416C355.475 131.416 361.815 131.65 365.454 122.865C369.094 114.08 364.515 107.17 364.515 107.17L356.649 111.269L352.305 126.965L355.475 131.416Z"
      fill="#101840"
    />
    <path d="M376.608 355.607V375.051L349.018 372.943V354.319L376.608 355.607Z" fill="#F48462" />
    <path
      d="M329.997 196.776C329.997 196.776 300.88 254.522 300.646 273.497C300.411 292.473 339.625 355.607 339.625 355.607C339.625 355.607 350.309 359.941 365.924 359.472C381.539 359.004 384.004 357.13 384.004 357.13C384.004 357.13 381.304 221.842 375.081 209.192C368.742 196.541 329.997 196.776 329.997 196.776Z"
      fill="#92B4EA"
    />
    <path
      d="M352.422 135.281C352.422 135.281 366.276 134.813 373.438 141.255C380.6 147.697 375.904 204.155 375.904 204.155C375.904 204.155 357.236 205.678 343.852 204.155C330.467 202.632 324.479 200.524 324.479 200.524C324.479 200.524 322.953 148.634 326.475 142.426C330.115 136.101 352.422 135.281 352.422 135.281Z"
      fill="#92B4EA"
    />
    <path
      d="M355.239 127.551L356.061 137.858C356.061 137.858 354.417 141.021 348.429 141.958C343.968 142.661 342.324 141.841 342.324 141.841L342.559 136.57L342.794 131.416L353.478 128.136L355.239 127.551Z"
      fill="#F48462"
    />
    <path
      d="M353.479 128.136C353.479 128.37 353.362 128.722 353.245 129.19C351.366 134.579 345.143 136.218 342.561 136.687L342.795 131.533L353.479 128.136Z"
      fill="#101840"
    />
    <path
      d="M338.451 108.107C338.451 108.107 334.107 121.577 336.925 128.488C339.626 135.516 346.2 133.759 349.957 131.884C353.714 130.127 355.358 127.551 355.358 127.551C355.358 127.551 359.115 128.37 361.346 124.739C363.459 121.108 361.581 117.243 359.35 116.657C357.119 115.954 354.771 117.711 354.771 117.711L356.415 113.729C356.297 113.846 346.083 95.6906 338.451 108.107Z"
      fill="#F48462"
    />
    <path
      d="M355.475 123.217C355.358 123.217 355.24 123.217 355.24 123.099C355.123 122.982 355.123 122.748 355.24 122.514L359.584 118.766C359.702 118.648 359.937 118.648 360.171 118.766C360.289 118.883 360.289 119.117 360.171 119.351L355.827 123.099C355.71 123.217 355.593 123.217 355.475 123.217Z"
      fill="#101840"
    />
    <path
      d="M359.702 122.865L356.532 122.28C356.297 122.28 356.18 122.045 356.18 121.811C356.18 121.577 356.414 121.46 356.649 121.46L359.819 122.045C360.054 122.045 360.172 122.28 360.172 122.514C360.054 122.748 359.937 122.865 359.702 122.865Z"
      fill="#101840"
    />
    <path
      d="M347.491 112.792C347.373 112.792 347.256 112.792 347.256 112.675C345.73 111.035 344.203 112.089 344.086 112.089C343.968 112.206 343.734 112.206 343.616 111.972C343.499 111.855 343.499 111.621 343.734 111.503C344.438 111.035 346.317 110.566 347.843 112.206C347.96 112.323 347.96 112.558 347.843 112.792C347.725 112.792 347.608 112.792 347.491 112.792Z"
      fill="#101840"
    />
    <path
      d="M337.393 113.143C337.276 113.143 337.158 113.143 337.158 113.026C337.041 112.909 337.041 112.675 337.158 112.441C338.567 111.152 339.624 111.386 339.741 111.503C339.976 111.621 340.094 111.738 339.976 111.972C339.859 112.206 339.741 112.206 339.507 112.206C339.507 112.206 338.802 111.972 337.745 113.026C337.628 113.143 337.511 113.143 337.393 113.143Z"
      fill="#101840"
    />
    <path
      d="M355.944 135.047C355.944 135.047 346.317 276.777 347.021 278.417C347.843 280.057 366.041 282.868 372.499 281.697C378.956 280.525 382.478 278.417 383.535 277.246C384.591 276.074 382.83 183.306 382.83 183.306L383.887 145.238C383.887 145.238 370.033 135.047 355.944 135.047Z"
      fill="white"
    />
    <path
      d="M370.268 170.07H358.058C357.002 170.07 356.18 169.25 356.18 168.195V166.087C356.18 165.033 357.002 164.213 358.058 164.213H370.268C371.325 164.213 372.147 165.033 372.147 166.087V168.195C372.147 169.132 371.325 170.07 370.268 170.07Z"
      fill="#92B4EA"
    />
    <path
      d="M339.742 123.685C339.625 123.685 339.39 123.568 339.39 123.451C338.333 119.117 340.916 116.892 341.034 116.892C341.151 116.774 341.386 116.774 341.621 116.892C341.738 117.009 341.738 117.243 341.621 117.477C341.503 117.594 339.273 119.351 340.212 123.334C340.212 123.451 340.095 123.685 339.742 123.685C339.86 123.685 339.86 123.685 339.742 123.685Z"
      fill="#101840"
    />
    <path
      d="M337.863 118.18C337.629 118.18 337.511 117.946 337.511 117.711L337.981 115.486L337.276 114.666C337.159 114.549 337.159 114.315 337.394 114.08C337.511 113.963 337.746 113.963 337.981 114.197L338.803 115.135C338.92 115.252 338.92 115.369 338.92 115.486L338.45 117.946C338.216 117.946 338.098 118.18 337.863 118.18Z"
      fill="#101840"
    />
    <path
      d="M343.969 126.965C343.265 126.965 342.795 126.731 342.795 126.731C342.678 126.731 342.561 126.614 342.561 126.379C342.561 126.262 342.561 126.145 342.678 126.028L345.965 123.802C346.083 123.685 346.2 123.685 346.318 123.802C346.435 123.92 346.552 124.037 346.435 124.154C346.318 125.325 345.848 126.145 345.026 126.614C344.791 126.848 344.322 126.965 343.969 126.965ZM343.852 126.145C344.204 126.145 344.556 126.145 344.791 125.911C345.143 125.677 345.378 125.442 345.613 124.974L343.852 126.145Z"
      fill="#101840"
    />
    <path
      d="M329.762 139.615C329.762 139.615 323.07 143.363 316.26 145.589C309.92 147.697 305.224 147.814 305.224 147.814C305.224 147.814 305.224 155.897 305.459 164.33C305.694 172.998 304.52 179.089 304.52 179.089C304.52 179.089 325.535 183.54 330.349 176.863C335.28 170.304 329.762 139.615 329.762 139.615Z"
      fill="#7772D3"
    />
    <path
      d="M289.14 128.136C289.14 128.136 285.265 117.36 282.095 117.126C278.925 116.892 282.682 125.677 282.682 125.677L273.994 122.397C273.994 122.397 264.132 119.703 262.136 120.523C260.14 121.343 263.428 124.622 263.428 124.622C263.428 124.622 256.383 122.514 257.44 125.911C258.379 129.308 263.193 131.299 263.193 131.299C263.193 131.299 256.383 128.839 257.91 132.822C258.732 134.93 273.877 144.066 283.974 143.832C294.188 143.481 291.371 136.687 291.371 136.687L289.14 128.136Z"
      fill="#F48462"
    />
    <path
      d="M266.95 133.29C266.832 133.29 266.715 133.29 266.715 133.173C266.598 133.056 266.598 132.821 266.715 132.587L275.403 125.091C275.521 124.974 275.755 124.974 275.99 125.091C276.108 125.208 276.108 125.442 275.99 125.676L267.302 133.173C267.185 133.29 267.067 133.29 266.95 133.29Z"
      fill="#101840"
    />
    <path
      d="M285.382 137.624C285.265 137.624 282.329 137.39 280.921 134.93C279.746 132.939 280.099 130.127 281.86 126.614C281.977 126.379 282.212 126.379 282.329 126.496C282.564 126.614 282.564 126.848 282.447 126.965C280.803 130.245 280.451 132.822 281.508 134.578C282.682 136.687 285.265 136.921 285.382 136.921C285.617 136.921 285.734 137.155 285.734 137.273C285.734 137.39 285.499 137.624 285.382 137.624Z"
      fill="#101840"
    />
    <path
      d="M339.507 355.607C339.272 355.607 339.154 355.49 339.154 355.256C336.689 312.385 337.393 243.511 337.511 239.06L324.009 231.095C323.774 230.978 323.774 230.744 323.892 230.627C324.009 230.393 324.244 230.393 324.361 230.51L338.098 238.592C338.215 238.709 338.333 238.826 338.333 238.943C338.333 239.646 337.393 311.214 339.976 355.256C339.976 355.49 339.741 355.607 339.507 355.607Z"
      fill="#474D66"
    />
    <path
      d="M337.747 285.679H337.629C336.221 285.21 324.01 281.111 323.188 277.48C323.071 277.011 323.071 276.308 323.893 275.723C324.48 275.137 325.302 274.903 326.124 275.02C330.702 275.489 337.747 284.742 338.099 285.093C338.216 285.21 338.216 285.445 338.099 285.562C337.982 285.562 337.864 285.679 337.747 285.679ZM325.771 275.606C325.184 275.606 324.715 275.723 324.362 276.074C324.01 276.426 323.893 276.777 323.893 277.128C324.362 279.588 332.346 282.868 336.573 284.391C334.577 281.931 329.411 275.84 326.006 275.606C326.006 275.606 325.889 275.606 325.771 275.606Z"
      fill="#474D66"
    />
    <path
      d="M374.142 394.963C376.021 394.846 377.547 393.206 377.664 391.215C377.782 383.133 378.956 365.095 377.547 365.095C375.668 365.095 356.648 370.014 356.648 370.014C356.648 370.014 356.296 358.77 348.43 360.761C342.442 362.283 335.398 369.194 332.463 372.474C331.523 373.528 330.349 374.231 329.058 374.465C323.422 375.754 308.042 380.205 307.69 388.404C307.338 398.477 311.329 397.306 313.208 397.423C314.852 397.189 361.11 395.549 374.142 394.963Z"
      fill="#4059AD"
    />
    <path
      d="M347.49 373.411C347.373 373.411 347.138 373.294 347.138 373.177C347.02 372.943 347.138 372.708 347.373 372.708L356.413 369.429C356.648 369.312 356.883 369.429 356.883 369.663C357 369.897 356.883 370.132 356.648 370.132L347.607 373.411H347.49Z"
      fill="white"
    />
    <path
      d="M344.439 118.18C344.204 118.18 344.087 117.946 344.087 117.712L344.557 115.486L343.617 114.549C343.5 114.432 343.5 114.198 343.617 113.963C343.735 113.846 343.97 113.846 344.204 113.963L345.261 115.018C345.378 115.135 345.378 115.252 345.378 115.369L344.909 117.829C344.791 118.063 344.674 118.18 344.439 118.18Z"
      fill="#101840"
    />
    <path
      d="M354.654 117.711L353.48 119.117L354.067 117.126C354.067 117.126 351.249 117.126 347.492 114.783C343.735 112.441 342.913 106.701 342.913 106.701C342.913 106.701 341.269 107.404 339.508 108.809C337.747 110.215 337.395 111.621 337.395 111.621C337.395 111.621 332.699 98.7361 342.561 97.0962C352.305 95.4564 359.232 103.656 359.232 103.656C359.232 103.656 364.868 104.476 365.338 109.864C365.925 115.369 362.05 119.586 362.05 119.586C362.05 119.586 359.585 115.135 354.654 117.711Z"
      fill="#101840"
    />
    <path
      d="M295.598 132.353L271.529 145.355C271.529 145.355 280.217 170.304 296.185 176.629C311.682 182.72 319.783 181.431 319.783 181.431L305.46 254.17C305.46 254.17 326.828 203.921 327.297 201.227C327.767 198.533 342.443 136.218 342.443 136.218C342.443 136.218 338.921 135.75 331.407 138.678C323.775 141.606 315.557 143.598 308.512 143.598C301.585 143.481 295.598 132.353 295.598 132.353Z"
      fill="white"
    />
    <path
      d="M342.325 141.958L341.738 146.643C341.738 146.643 354.653 141.255 355.475 138.912C356.297 136.453 342.912 140.318 342.912 140.318L342.325 141.958Z"
      fill="#F48462"
    />
    <path
      d="M341.739 146.994C341.622 146.994 341.622 146.994 341.504 146.877C341.387 146.76 341.387 146.643 341.387 146.526L341.856 138.21C341.856 137.975 341.974 137.858 342.209 137.858C342.443 137.858 342.561 138.092 342.561 138.21L342.091 145.94C344.439 145.003 353.362 141.021 355.123 138.561C355.241 138.444 355.476 138.327 355.593 138.444C355.71 138.561 355.828 138.795 355.71 138.912C353.597 141.958 342.326 146.643 341.856 146.877C341.856 146.994 341.856 146.994 341.739 146.994Z"
      fill="white"
    />
    <path
      d="M369.915 274.2C365.219 274.2 356.531 273.38 355.827 272.56C355.24 271.74 355.944 251.359 356.414 242.691C356.414 242.457 356.531 242.34 356.766 242.34H377.547C377.782 242.34 377.899 242.457 377.899 242.691V272.326C377.899 272.443 377.782 272.56 377.782 272.677C377.664 272.677 376.021 273.849 371.911 274.2C371.324 274.083 370.62 274.2 369.915 274.2ZM357.118 243.043C356.648 254.288 356.061 271.037 356.531 272.092C357.235 272.677 367.685 273.732 371.794 273.38C374.964 273.146 376.608 272.326 377.195 272.092V243.043H357.118Z"
      fill="#D8DAE5"
    />
    <path
      d="M332.346 214.346L301.703 214.463C300.646 214.463 299.472 213.526 299.12 212.354L287.379 165.15C287.144 163.979 287.731 163.042 288.905 163.042L319.549 162.925C320.605 162.925 321.779 163.862 322.132 165.033L333.872 212.237C334.107 213.409 333.403 214.346 332.346 214.346Z"
      fill="#101840"
    />
    <path
      d="M328.705 214.346L298.062 214.463C297.005 214.463 295.831 213.526 295.479 212.354L283.856 165.15C283.621 163.979 284.208 163.042 285.382 163.042L316.025 162.925C317.082 162.925 318.256 163.862 318.608 165.033L330.349 212.237C330.466 213.409 329.879 214.346 328.705 214.346Z"
      fill="#474D66"
    />
    <path
      d="M308.748 154.608C308.63 154.608 308.63 154.608 308.513 154.608C307.926 154.608 307.456 154.257 307.221 153.788C305.578 151.328 308.161 143.715 308.278 143.481C308.278 143.363 308.513 143.246 308.63 143.246C308.748 143.246 308.982 143.363 308.982 143.481C309.217 144.301 311.565 152.031 309.922 154.14C309.57 154.491 309.217 154.608 308.748 154.608ZM308.63 144.886C307.926 147.463 306.869 151.914 307.926 153.437C308.161 153.788 308.395 153.905 308.748 153.905C309.1 153.905 309.217 153.905 309.452 153.671C310.391 152.383 309.335 147.697 308.63 144.886Z"
      fill="#D8DAE5"
    />
    <path
      d="M343.264 181.197C343.264 181.197 333.871 178.034 331.993 178.152C330.232 178.269 325.653 179.674 325.653 179.674L326.24 182.251C326.24 182.251 310.39 184.008 310.39 187.054C310.39 189.162 317.317 188.811 317.317 188.811C317.317 188.811 310.273 189.748 310.39 192.207C310.625 194.667 319.43 194.316 319.43 194.316C319.43 194.316 312.151 195.721 314.852 197.83C317.552 199.938 340.798 202.398 344.321 196.658C347.843 190.919 343.264 181.197 343.264 181.197Z"
      fill="#F48462"
    />
    <path
      d="M383.888 145.238C383.888 145.238 410.304 170.304 404.786 191.856C397.39 220.319 340.447 203.687 340.447 203.687L342.326 177.215L376.609 175.341L383.888 145.238Z"
      fill="white"
    />
    <path
      d="M382.127 176.278C378.487 176.278 374.378 175.809 374.143 175.809C374.026 175.809 373.791 175.692 373.791 175.458C373.791 175.341 373.791 175.106 374.026 175.106C379.074 172.647 385.414 173.115 387.058 174.286C387.41 174.521 387.528 174.872 387.528 174.989C387.528 175.341 387.293 175.575 386.823 175.692C385.767 176.161 384.005 176.278 382.127 176.278ZM375.67 175.224C378.487 175.458 384.475 175.809 386.471 175.106C386.588 175.106 386.706 174.989 386.706 174.989C386.119 174.286 380.835 173.232 375.67 175.224Z"
      fill="#D8DAE5"
    />
    <path
      d="M325.887 182.134C325.653 182.134 325.535 182.017 325.535 181.783C325.535 181.549 325.653 181.431 325.887 181.314L329.997 180.494C330.231 180.494 330.349 180.612 330.466 180.846C330.466 181.08 330.349 181.197 330.114 181.314L325.887 182.134Z"
      fill="#101840"
    />
    <path
      d="M316.26 189.162C316.026 189.162 315.908 189.045 315.908 188.811C315.908 188.576 316.026 188.459 316.26 188.342L326.827 187.054C327.062 187.054 327.179 187.171 327.297 187.405C327.297 187.639 327.179 187.757 326.944 187.874L316.26 189.162Z"
      fill="#101840"
    />
    <path
      d="M318.139 194.902C317.905 194.902 317.787 194.784 317.787 194.55C317.787 194.316 317.905 194.199 318.139 194.082L328.941 192.676C329.176 192.676 329.293 192.793 329.41 193.027C329.41 193.262 329.293 193.379 329.058 193.496L318.139 194.902C318.257 194.902 318.257 194.902 318.139 194.902Z"
      fill="#101840"
    />
    <path
      d="M306.516 170.421H299.119C297.123 170.421 295.479 169.133 295.01 167.259L293.953 163.159C293.953 162.925 294.071 162.807 294.188 162.69C294.423 162.69 294.54 162.808 294.658 162.925L295.714 167.024C296.066 168.547 297.475 169.601 299.119 169.601H306.516C307.337 169.601 308.159 169.25 308.746 168.547C309.333 167.844 309.451 167.024 309.333 166.087L308.629 163.042C308.629 162.807 308.746 162.69 308.864 162.573C309.099 162.573 309.216 162.69 309.333 162.808L310.038 165.853C310.273 166.907 310.038 168.078 309.333 168.898C308.629 169.953 307.69 170.421 306.516 170.421Z"
      fill="white"
    />
    <path
      d="M359.818 148.283C359.466 148.166 359.231 147.932 359.348 147.697C360.405 143.246 360.287 139.732 358.879 137.507C357.822 135.867 356.413 135.516 356.296 135.516C356.061 135.516 355.826 135.164 355.943 134.93C355.943 134.696 356.296 134.461 356.53 134.579C356.648 134.579 358.409 134.93 359.7 137.038C361.227 139.498 361.461 143.129 360.405 147.932C360.287 148.049 360.053 148.283 359.818 148.283Z"
      fill="#253776"
    />
    <path
      d="M335.397 159.762C335.163 159.762 334.928 159.528 334.928 159.294C334.928 158.474 334.81 140.084 341.737 135.047C341.972 134.93 342.324 134.93 342.442 135.164C342.559 135.399 342.559 135.75 342.324 135.867C335.867 140.552 335.867 159.059 335.867 159.294C335.867 159.528 335.632 159.762 335.397 159.762Z"
      fill="#253776"
    />
    <path
      d="M358.88 164.799C358.763 164.799 358.763 164.799 358.646 164.799C358.411 164.681 358.293 164.447 358.411 164.096C358.411 163.979 363.342 152.734 359.702 148.869C359.233 148.4 358.763 148.166 358.059 148.283C354.771 148.517 349.723 155.428 347.962 158.005C347.844 158.239 347.492 158.356 347.257 158.122C347.022 158.005 346.905 157.653 347.14 157.419C347.375 157.068 353.48 147.58 357.941 147.229C358.88 147.112 359.702 147.463 360.407 148.166C364.399 152.5 359.467 163.979 359.233 164.447C359.233 164.681 359.115 164.799 358.88 164.799Z"
      fill="#253776"
    />
    <path
      d="M338.451 160.231C338.451 161.87 337.159 163.276 335.398 163.276C333.755 163.276 332.346 161.987 332.346 160.231C332.346 158.591 333.637 157.185 335.398 157.185C337.042 157.185 338.451 158.591 338.451 160.231Z"
      fill="#253776"
    />
    <path
      d="M336.573 160.23C336.573 160.816 336.103 161.402 335.399 161.402C334.694 161.402 334.225 160.933 334.225 160.23C334.225 159.528 334.694 159.059 335.399 159.059C336.103 159.059 336.573 159.528 336.573 160.23Z"
      fill="#A37B90"
    />
    <path
      d="M349.838 158.708H345.964C345.729 158.708 345.494 158.474 345.494 158.239C345.494 158.005 345.729 157.771 345.964 157.771H349.838C350.073 157.771 350.308 158.005 350.308 158.239C350.308 158.474 350.19 158.708 349.838 158.708Z"
      fill="#253776"
    />
    <path
      d="M359.466 166.438C359.349 166.438 359.114 166.321 358.997 166.204L357.001 162.925C356.884 162.69 356.884 162.339 357.118 162.222C357.353 162.105 357.705 162.105 357.823 162.339L359.819 165.619C359.936 165.853 359.936 166.204 359.701 166.321C359.584 166.438 359.584 166.438 359.466 166.438Z"
      fill="#253776"
    />
    <path
      d="M75.0586 53L76.0276 53.5578L75 53.9983"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="76" cy="51" r="1.875" stroke="white" stroke-width="0.25" />
    <circle cx="81" cy="51" r="1.875" stroke="white" stroke-width="0.25" />
    <path
      d="M79 50.9999C78.8333 50.8332 78.4 50.5999 78 50.9999"
      stroke="white"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
