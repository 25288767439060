import React from "react";
import styled from "styled-components";
import { Avatar, colors, spacing } from "ui-kit";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.almostBlack};
`;

const AvatarImage = styled(Avatar)`
  display: inline-block;
  margin-right: ${spacing.s};
`;

export interface AvatarInfoProps {
  children: React.ReactNode;
}

export const AvatarInfo = ({ children }: AvatarInfoProps) => {
  return (
    <Wrapper>
      <AvatarImage />
      <div>{children}</div>
    </Wrapper>
  );
};
