import { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spinner } from "evergreen-ui";
import { Assessment, badScoreCategoryTreshold, FormEntry, mapTextToAcceptedLanguage, ScoreCategory } from "shared";
import { useQuery } from "react-query";
import { Container, mobileTreshold, spacing } from "ui-kit";
import { AvatarInfo, PageWrapper } from "../../components";
import { usePatientStore, useStore } from "../../stores";
import AssessmentCard from "./assessment-card";
import _ from "lodash";
import ContactForm from "./contact-form";
import ContactBar, { CONTACT_BAR_HEIGHT_DESKTOP, CONTACT_BAR_HEIGHT_MOBILE } from "./contact-bar";

const NO_ENTRIES = 0;

const AugmentedPageWrapper = styled(PageWrapper)`
  --contact-bar-height: ${CONTACT_BAR_HEIGHT_DESKTOP};

  @media (max-width: ${mobileTreshold}px) {
    --contact-bar-height: ${CONTACT_BAR_HEIGHT_MOBILE};
  }

  display: flex;
  width: 100%;
  padding-bottom: calc(var(--contact-bar-height) + ${spacing.l});
`;

const AssessmentContentContainer = styled(Container)`
  & > h1 {
    margin-bottom: ${spacing.xl};
  }

  & > h2 {
    margin-top: ${spacing.xl};
  }
`;

const CenteredRow = styled.div`
  text-align: center;
  margin-top: ${spacing.xl};

  & > div {
    margin: auto;
  }
`;

export const AssessmentPage = () => {
  const rootStore = useStore();
  const patientStore = usePatientStore();
  const { token } = useParams();
  const { t, i18n } = useTranslation("assessment");
  const { language } = i18n;
  const lang = mapTextToAcceptedLanguage(language);

  // States

  const [assessment, setAssessment] = useState<Assessment | undefined>();
  const [contactFormIsShown, setContactFormIsShown] = useState(false);
  const [showHomeButton, setShowHomeButton] = useState(false);

  // Network

  const { isLoading, isError } = useQuery(
    "fetch-assessment",
    async () => {
      const data = await rootStore.executeRequest<Assessment | undefined>({
        method: "get",
        url: `/assessments/${token}`,
      });

      setAssessment(data);
      patientStore.setPatientUserRelation(data?.patient!.patientUserRelation);
    },
    { refetchOnWindowFocus: false }
  );

  // Utils

  const extractScoreCategory = (formEntry: FormEntry): ScoreCategory =>
    _.get(formEntry, "scoreCategory", 0) as ScoreCategory;
  const extractFormName = (formEntry: FormEntry): string => _.get(formEntry, ["form", "multilingualName", lang], "");
  const extractFormFeedback = (formEntry: FormEntry): string =>
    _.get(formEntry, ["feedback", "multilingualText", lang], "");

  // Rendering

  const loadingContent = (
    <CenteredRow>
      <Spinner />
    </CenteredRow>
  );

  const errorContent = (
    <AvatarInfo>
      <p>
        {t("descriptionError")}
        <br />
        <a target="_blank" href={`mailto:${t("supportEmail")}`} rel="noreferrer">
          {t("supportLink")}
        </a>
      </p>
    </AvatarInfo>
  );

  const formEntries = assessment?.formEntries || [];
  const goodGradeFormEntriess = formEntries.filter(
    (entry: FormEntry) => extractScoreCategory(entry) < badScoreCategoryTreshold
  );
  const badGradeFormEntriess = formEntries.filter(
    (entry: FormEntry) => extractScoreCategory(entry) >= badScoreCategoryTreshold
  );

  useEffect(() => {
    setShowHomeButton(badGradeFormEntriess.length === NO_ENTRIES || (assessment?.contactPreferencesAreSet as boolean));
  }, [assessment, badGradeFormEntriess.length]);

  const assessmentContent = (
    <>
      <h1>{t("header", { name: assessment?.patient!.firstName || "" })}</h1>
      <AvatarInfo>
        <p>{t("description")}</p>
      </AvatarInfo>
      {goodGradeFormEntriess.length > NO_ENTRIES && <h2>{t("goodGradesHeader")}</h2>}
      {goodGradeFormEntriess.map((entry: FormEntry) => (
        <AssessmentCard
          scoreCategory={extractScoreCategory(entry)}
          formName={extractFormName(entry)}
          feedback={extractFormFeedback(entry)}
        />
      ))}
      {badGradeFormEntriess.length > NO_ENTRIES && <h2>{t("badGradesHeader")}</h2>}
      {badGradeFormEntriess.map((entry: FormEntry) => (
        <AssessmentCard
          scoreCategory={extractScoreCategory(entry)}
          formName={extractFormName(entry)}
          feedback={extractFormFeedback(entry)}
        />
      ))}
    </>
  );

  const PageContainer = ({ children }: { children: React.ReactNode }) => (
    <AugmentedPageWrapper withNav lightBackground>
      {children}
      <ContactBar t={t} setContactFormIsShown={setContactFormIsShown} showHomeButton={showHomeButton} />
      <ContactForm
        contactFormIsShown={contactFormIsShown}
        setContactFormIsShown={setContactFormIsShown}
        setAssessment={setAssessment}
      />
    </AugmentedPageWrapper>
  );

  return (
    <PageContainer>
      <AssessmentContentContainer>
        {isLoading && loadingContent}
        {!isLoading && isError && errorContent}
        {!isLoading && !isError && assessmentContent}
      </AssessmentContentContainer>
    </PageContainer>
  );
};
