import styled from "styled-components";
import { Textarea as EGTextarea } from "evergreen-ui";
import { colors, componentBorderWidth, focusShadow, fonts, spacing } from "../../styling.constants";

export const Textarea = styled(EGTextarea)`
  font-family: ${fonts.body1.family};
  font-size: ${fonts.body1.size};
  font-weight: ${fonts.body2.weight};
  border-width: ${componentBorderWidth};
  border-radius: ${spacing.s};
  padding: ${spacing.s};
  height: ${spacing.xl};

  background-color: ${colors.white70};
  color: ${colors.darkBlue};
  border-color: ${props => (props.isInvalid ? colors.primaryRed : colors.lightGrey)};

  ::placeholder {
    color: ${colors.darkGrey};
  }

  &:focus {
    box-shadow: ${focusShadow};
  }

  &:disabled {
    background-color: ${colors.lightGrey};
  }
`;
