import _ from "lodash";
import { init, formula } from "expressionparser";
import { Answer, Field, Option } from "./types";
import { defaultAnswerScore } from "./constants";

/**
 * Function that creates an answer from an option and a field
 * @param option the option
 * @returns The answer
 */

export const mapOptionToAnswer = ({ key, fieldKey, score }: Option): Answer => ({
  fieldKey: fieldKey,
  score,
  optionKey: key,
});

/**
 * Function that calculates the score for a form entry submission
 * @param form the form
 * @param answers The submitted answers
 * @returns THe score
 */

export const calculateFormScore = (form: { scoreLogic?: string | null }, answers: Answer[]): number => {
  if (!form.scoreLogic) return _.sumBy(answers, ({ score = defaultAnswerScore }: Answer) => score);

  const score = evaluateLogicalExpression(form.scoreLogic, answers);
  if (typeof score !== "number") throw new Error("Invalid logical expression");
  return score;
};

/**
 * Function that calculates the score category for a specific score to a form
 * @param form the form
 * @param score The score
 * @returns THe score category
 */

export const calculateFormScoreCategory = (form: { scoreThresholds: number[] }, score: number) => {
  for (let i = 0; i < form.scoreThresholds.length; i++) {
    if (score < form.scoreThresholds[i]) return i;
  }
  return form.scoreThresholds.length;
};

/**
 * Function that sorts any array of object with an index by index
 * @param indexables the objects to sort
 * @returns The sorted objects
 */

export const sortByIndex = <Type extends { index: number }>(indexables: Type[]): Type[] =>
  _.sortBy(indexables, "index");

/**
 * Function that build an array of required fields from an array of fields with conditions and an array of answers
 * @param fields the array of fields
 * @param answers the array of submitted answers
 * @returns The array of required fields
 */

export const buildRequiredFieldsArray = (fields: Field[], answers: Answer[]) => {
  return sortByIndex(fields).filter(field => {
    if (!field.fieldCondition) return true;

    const isConditionMet = evaluateLogicalExpression(field.fieldCondition.logic, answers);
    if (typeof isConditionMet !== "boolean") throw new Error("Invalid logical expression");
    return isConditionMet;
  });
};

/**
 * Function that takes a logical expression, replaces the field keys by their score in the provided answers (or 0 if not found) and returns it's value
 * @param expression string
 * @param answers the array of submitted answers
 * @returns A boolean or a number of the value returned when evaluating the expression
 */

export const evaluateLogicalExpression = (
  expression: string,
  answers: { fieldKey: string; score?: number | null }[]
): boolean | number => {
  const parser = init(formula, (term: string) => {
    const answer = answers.find(({ fieldKey }) => term === fieldKey);
    if (answer) return answer.score || defaultAnswerScore;
    return defaultAnswerScore;
  });

  const value = parser.expressionToValue(expression);
  if (typeof value === "boolean") return value;
  if (typeof value === "number") return value;

  throw new Error("Invalid logical expression");
};

/**
 * Function that takes a form with it's fields to validate the form's score logic
 * @param form the form
 * @param fields the form's fields
 * @returns a boolean indicating if the form logic is valid
 */
// TODO: Implement tests for this
export const isFormScoreLogicValid = (
  form: { scoreLogic?: string | null },
  fields: { key: string; score?: number | null }[]
): boolean => {
  if (_.isNil(form.scoreLogic)) return true;

  try {
    const value = evaluateLogicalExpression(
      form.scoreLogic,
      fields.map(({ key, score }) => ({ fieldKey: key, score }))
    );
    return typeof value === "number";
  } catch {
    return false;
  }
};

/**
 * Function that takes a field condition with an array of fields to validate the field conditions's logic
 * @param fieldCondition the field conditions
 * @param fields the fields
 * @returns a boolean indicating if the field condition's logic is valid
 */
// TODO: Implement tests for this
export const isFieldConditionLogicValid = (
  fieldCondition: { logic: string },
  fields: { key: string; score?: number | null }[]
): boolean => {
  try {
    const value = evaluateLogicalExpression(
      fieldCondition.logic,
      fields.map(({ key, score }) => ({ fieldKey: key, score }))
    );
    return typeof value === "boolean";
  } catch {
    return false;
  }
};
