import React from "react";
import styled from "styled-components";
import { colors, spacing } from "../../styling.constants";
import { Star } from "../star";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & input,
  & select {
    width: 100%;
  }

  & p {
    color: ${colors.almostBlack};
    margin-bottom: ${spacing.xs};
  }
  & label {
    display: inline-block;
    margin-top: ${spacing.xs};
  }
`;

const ErrorLabel = styled.label`
  color: ${colors.primaryRed};
`;

interface FieldProps {
  label?: string;
  description?: string;
  error?: string;
  isRequired?: boolean;
  children: React.ReactElement;
}

export const Field = ({ label, error, description, isRequired, children }: FieldProps) => {
  return (
    <Wrapper>
      {label && (
        <p>
          {isRequired && <Star />}
          <b>{label}</b>
        </p>
      )}
      {description && <label>{description}</label>}
      {children}
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </Wrapper>
  );
};
