import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon, ArrowLeftIcon } from "evergreen-ui";
import { Button, spacing, colors } from "ui-kit";
import { AvatarInfo } from "../";
import { fadeIn, slideFadeInLeftAnimation, slideFadeInRightAnimation } from "../../utils/animations";

const animationDuration = "1s";

const Wrapper = styled.div`
  flex: 1;
  margin-top: ${spacing.xl};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
  animation-name: ${fadeIn};
  animation-duration: ${animationDuration};

  & label {
    color: ${colors.darkGrey};
  }
`;

const ChildrenWrapper = styled.div`
  margin-top: ${spacing.m};
  animation-duration: ${animationDuration};

  &.forward {
    animation-name: ${slideFadeInLeftAnimation};
  }

  &.backward {
    animation-name: ${slideFadeInRightAnimation};
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.m};

  & button:disabled {
    background-color: transparent !important;
    border-color: transparent !important;
    opacity: 0.2;
  }
`;

interface FormWrapperProps {
  fieldKey: string;
  fieldTitle: string;
  fieldSubtitle?: string | null;
  children: React.ReactNode;
  isValid?: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  direction?: "forward" | "backward";
}

const FormWrapper = ({
  fieldKey,
  fieldTitle,
  fieldSubtitle,
  children,
  isValid = false,
  onClickNext,
  onClickPrevious,
  direction = "forward",
}: FormWrapperProps) => {
  const { t } = useTranslation("forms");

  // Handlers

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isValid) return;
    if (e.key === "Enter") onClickNext();
  };

  // Rendering

  return (
    <Wrapper onKeyDown={onKeyDown}>
      <div>
        <AvatarInfo>
          <FieldWrapper key={fieldKey}>
            <h2>{fieldTitle}</h2>
            {fieldSubtitle && <label>{fieldSubtitle}</label>}
          </FieldWrapper>
        </AvatarInfo>
        <ChildrenWrapper className={direction} key={fieldKey}>
          {children}
        </ChildrenWrapper>
      </div>

      <FooterWrapper>
        <Button onClick={onClickPrevious} appearance="minimal" iconBefore={<ArrowLeftIcon />}>
          {t("previous")}
        </Button>
        <Button
          onClick={onClickNext}
          appearance="primary"
          iconAfter={<ArrowRightIcon />}
          disabled={!isValid}
          transition="background-color 0.4s">
          {t("next")}
        </Button>
      </FooterWrapper>
    </Wrapper>
  );
};

export default FormWrapper;
