import styled from "styled-components";
import { ScoreCategory } from "shared";
import { AIcon, BIcon, CIcon, DIcon, colors, spacing, StarIcon } from "ui-kit";

interface ScoreProps extends React.HTMLAttributes<HTMLDivElement> {
  scoreCategory: ScoreCategory;
}

const mapScoreCategoryToIcon = (scoreCategory: ScoreCategory) => {
  switch (scoreCategory) {
    case 0:
      return (
        <>
          <AIcon />
          <StarIcon />
        </>
      );
    case 1:
      return <BIcon />;
    case 2:
      return <CIcon />;
    case 3:
      return <DIcon />;
  }
};

const mapScoreCategoryToBackgroundColor = (scoreCategory: ScoreCategory) => {
  switch (scoreCategory) {
    case 0:
      return colors.gradeA;
    case 1:
      return colors.gradeB;
    case 2:
      return colors.gradeC;
    case 3:
      return colors.gradeD;
  }
};

const Score = (props: ScoreProps) => {
  return <div {...props}>{mapScoreCategoryToIcon(props.scoreCategory)}</div>;
};

const StyledScore = styled(Score)`
  position: relative;
  width: ${spacing.xl};
  height: ${spacing.xl};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => mapScoreCategoryToBackgroundColor(props.scoreCategory)};
  border-radius: 50%;
`;

export default StyledScore;
