import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { AcceptedLanguage, acceptedLanguageArray, FrontPageText } from "shared";
import { RootStore } from "../stores/root";

// I kind of over did it here but basically, if you want to add a new translation file just add it's filename to the namespacesArray
// And if you want to add a language to the app, just add its lang code to the acceptedLanguageArray

// Types and method needed to build the resources from the arrays

export const namespacesArray = ["confirmation", "forms", "general", "home", "assessment", "legal"];
export type Namespace = typeof namespacesArray[number];
type Resource = {
  [k in Namespace]: { string: string };
};

const mapNamespaceToFile = (ns: Namespace, lang: AcceptedLanguage) => require(`./${lang}/${ns}.json`);

// Generate resource object from namespaces array and accepted language array

let resources: { [k in AcceptedLanguage]: Resource } = { en: {}, fr: {} };

acceptedLanguageArray.forEach((language) => {
  let resourceDictionary: Resource = {};
  namespacesArray.forEach((ns) => (resourceDictionary[ns] = mapNamespaceToFile(ns, language)));
  resources[language] = resourceDictionary;
});

export const fetchFrontPageText = async (language: string) => {
  const rootStore = new RootStore();

  const data = (await rootStore.executeRequest<FrontPageText[]>({
    method: "get",
    url: `/translations/front_page_text`,
  })) as FrontPageText[];

  return data.map((text: FrontPageText) => {
    return language === "en" ? text.textEn : text.textFr;
  });
};
// Build i18n for the entire project

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    defaultNS: "general",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
