import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Answer, Form } from "shared";
import { Container, Stepper } from "ui-kit";
import { Form as FormComponent, PageWrapper } from "../../components";
import routes from "../../utils/routes";
import PatientUserRelationForm from "./patient-user-relation-form";
import { usePatientStore } from "../../stores";
import { useFormStore } from "../../stores";

const FormPageWrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FormsPage = observer(() => {
  const patientStore = usePatientStore();
  const navigate = useNavigate();
  const formStore = useFormStore();

  if (!formStore.forms) navigate(routes.home.path);

  // Handlers

  const onClickPrevious = () => {
    if (formStore.isInitialField) {
      if (patientStore.patient) patientStore.setPatient(undefined);
      else if (patientStore.patientUserRelation) patientStore.setPatientUserRelation(undefined);
    }

    formStore.decrementFieldIndex();
  };

  const onClickNext = () => {
    if (formStore.isFinalField) {
      navigate(routes.confirm.path);
    }

    formStore.incrementFieldIndex();
  };

  // Rendering

  // TODO: Small glitch between both forms where when backing from forms flow to patient form, the direction is forward
  const content = patientStore.showPatientUserRelationField ? (
    <PatientUserRelationForm />
  ) : (
    <FormComponent
      onClickPrevious={onClickPrevious}
      onClickNext={onClickNext}
      currentField={formStore.currentField}
      currentAnswer={formStore.currentAnswer}
      onSelectAnswer={(answer: Answer | undefined) => {
        if (answer) formStore.saveAnswer(answer);
      }}
    />
  );

  return (
    <FormPageWrapper withNav lightBackground>
      <FormContainer>
        <Stepper>
          {(formStore.forms || []).map(({ key }: Form, index: number) => (
            <Stepper.Step key={key} isActive={formStore.currentFormIndex >= index} />
          ))}
        </Stepper>
        {content}
      </FormContainer>
    </FormPageWrapper>
  );
});
