import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Answer, Field, mapTextToAcceptedLanguage } from "shared";
import { FormProvider } from "./context";
import FormWrapper from "./wrapper";
import RadioField from "./radio-field";

export interface FormProps {
  onClickPrevious: () => void;
  onClickNext: () => void;
  onSelectAnswer?: (answer: Answer | undefined) => void;
  currentField: Field;
  currentAnswer?: Answer;
}

export const Form = ({ onClickPrevious, onClickNext, onSelectAnswer, currentField, currentAnswer }: FormProps) => {
  const { i18n } = useTranslation("general");
  const { language } = i18n;

  // States

  const [answer, setAnswer] = useState<undefined | Answer>(currentAnswer);
  const [isValid, setIsValid] = useState(false);
  const [direction, setDirection] = useState<"forward" | "backward">("forward");

  // Effects

  useEffect(() => {
    if (onSelectAnswer && currentAnswer !== answer) onSelectAnswer(answer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  useEffect(() => {
    setAnswer(currentAnswer);
  }, [currentField, currentAnswer]);

  // Handlers

  const _onClickNext = () => {
    setDirection("forward");
    onClickNext();
  };

  const _onClickPrevious = () => {
    setDirection("backward");
    onClickPrevious();
  };

  // Rendering

  const { key, type, multilingualText, multilingualSubtext } = currentField;
  const lang = mapTextToAcceptedLanguage(language);
  const title = multilingualText[lang] || "";
  const subtitle = multilingualSubtext ? multilingualSubtext[lang] || "" : undefined;

  const renderField = () => {
    switch (type) {
      case "radio":
        return <RadioField />;
      default:
        throw new Error(`Field type ${type} not yet supported`);
    }
  };

  return (
    <FormWrapper
      fieldKey={key}
      fieldTitle={title}
      fieldSubtitle={subtitle}
      isValid={isValid}
      onClickNext={_onClickNext}
      onClickPrevious={_onClickPrevious}
      direction={direction}>
      <FormProvider
        currentField={currentField}
        isValid={isValid}
        setIsValid={setIsValid}
        answer={answer}
        setAnswer={setAnswer}
        onClickNext={_onClickNext}
        onClickPrevious={_onClickPrevious}>
        {renderField()}
      </FormProvider>
    </FormWrapper>
  );
};
