import { useTranslation } from "react-i18next";
import { FormikSelect, FormikTextarea, FormikTextInputField } from "../../components";
import { patientUserRelationsArray } from "shared";
import { FormikTouched, FormikErrors } from "formik";
import { IdentityFormValues } from ".";

export const limitedPatientUserRelationsArray = patientUserRelationsArray.filter(
  (relation) => relation !== "self" && relation !== "patient"
);

interface UserFormProps {
  touched: FormikTouched<IdentityFormValues>;
  errors: FormikErrors<IdentityFormValues>;
}

const UserForm = ({ touched, errors }: UserFormProps) => {
  const { t } = useTranslation("general");

  return (
    <div>
      <FormikTextInputField
        errors={errors}
        touched={touched}
        path={"user.firstName"}
        label={t("firstName")}
        isRequired
      />
      <FormikTextInputField errors={errors} touched={touched} path={"user.lastName"} label={t("lastName")} isRequired />

      <FormikTextInputField errors={errors} touched={touched} path={"user.email"} label={t("email")} isRequired />

      <FormikTextInputField errors={errors} touched={touched} path={"user.phoneNumber"} label={t("phoneNumber")} />

      <FormikSelect
        isRequired
        OptionsArray={limitedPatientUserRelationsArray}
        t={t}
        errors={errors}
        touched={touched}
        path={"user.role"}
        label={t("role")}
        placeHolder={t("roleSelectPlaceholder")}
      />

      <FormikTextarea
        errors={errors}
        touched={touched}
        path={"user.roleDescription"}
        label={t("roleDescriptionLabel")}
        placeHolder={t("typeHerePlaceholder")}
      />
    </div>
  );
};

export default UserForm;
