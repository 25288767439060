import React, { cloneElement, useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { componentBorderWidth, mobileTreshold, spacing } from "ui-kit";
import RadioOption from "./radio-options";
import { useWindowSize } from "../../utils/use-window-size";

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${spacing.m};

  & > button {
    flex: 1;
    margin-right: ${spacing.m};
    padding: ${spacing.s} ${spacing.m};
  }

  & > button:last-of-type {
    margin-right: 0;
  }

  & > div {
    flex: 1;
    margin-left: ${spacing.m};
    padding: ${spacing.s} ${spacing.m};
    border: ${componentBorderWidth} solid transparent;
  }
`;

export interface RadioInputProps {
  children: JSX.Element[];
  value?: string | number | null;
  onSelect?: (value: any) => void;
}

export const RadioInput = ({ children, value, onSelect }: RadioInputProps) => {
  const { width } = useWindowSize();

  // States

  const [_value, setValue] = useState<any | undefined>(value);

  // Effects

  useEffect(() => {
    if (value !== _value) setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Handlers

  const onSelectOption = (value: any) => {
    setValue(value);
    if (onSelect) onSelect(value);
  };

  // Rendering

  const augmentChild = (child: JSX.Element) => {
    const value = child.props.value;
    const augmentedProps = { onClick: () => onSelectOption(value), isSelected: _value === value };

    return cloneElement(child, augmentedProps);
  };

  const optionsPerRow = width < mobileTreshold ? 1 : children.length > 3 ? 2 : children.length;
  let rows = _.chunk(children, optionsPerRow);

  return (
    <div>
      {rows.map((row, index) => (
        <Row key={`row-${index}`}>
          {row.map(augmentChild)}
          {/* Add hidden div at the end of the row to make all buttons egal width */}
          {optionsPerRow > row.length && [...Array(optionsPerRow - row.length)].map(() => <div />)}
        </Row>
      ))}
    </div>
  );
};

RadioInput.Option = RadioOption;
