import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toaster } from "evergreen-ui";
import _ from "lodash";
import i18n from "../translations";
import { FormStore } from "./forms";
import { PatientStore } from "./patient";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000/api-user";

const isAxiosError = (error: any): error is AxiosError => {
  return error && error.isAxiosError;
};

export class RootStore {
  public patientStore: PatientStore;
  public formStore: FormStore;

  constructor() {
    this.patientStore = new PatientStore(this);
    this.formStore = new FormStore(this);
  }

  public async executeRequest<ResponseData>(
    this: RootStore,
    options: AxiosRequestConfig
  ): Promise<ResponseData | undefined> {
    const { language, t } = i18n;
    const defaultErrorMessage = t("error", { ns: "general" });

    try {
      if (!options.headers) options.headers = {};
      options.headers["accept-language"] = language;

      const { data } = await axios.request(options);
      return data as ResponseData;
    } catch (e) {
      console.log(e);

      if (isAxiosError(e)) {
        const status = e.response?.status;
        const message = _.get(e, "response.data.message", defaultErrorMessage);

        if (status === 401) {
          toaster.danger(message);
        } else if (status === 400) {
          // TODO: There might be more assertions needed if other bad requests error causes
          const validationString: string = _.get(e, "response.data.error", "");
          toaster.danger(message, { description: validationString });
        } else {
          toaster.danger(message);
        }
      } else {
        toaster.danger(defaultErrorMessage);
      }
    }
  }
}
