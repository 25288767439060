import styled from "styled-components";
import { colors, fonts, spacing } from "../../styling.constants";

export const Link = styled.a`
  font-family: ${fonts.body1.family};
  font-size: ${fonts.body1.size};
  font-weight: ${fonts.body1.weight};
  color: ${colors.primaryBlue};
  border-bottom: 2.5px solid ${colors.primaryBlue};
  padding: ${spacing.xs} 0;
  text-decoration: none;

  &:disabled {
    color: ${colors.darkGrey};
    border-color: ${colors.lightGrey};
  }

  &:hover,
  &:active {
    color: ${colors.darkBlue};
    border-color: ${colors.darkBlue};
  }
`;
