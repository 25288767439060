import { Field, Input, mobileTreshold, Select, spacing, Textarea } from "ui-kit";
import { Field as FormikField, FieldProps, FormikErrors, FormikTouched } from "formik";
import _ from "lodash";
import styled from "styled-components";
import { Checkbox } from "evergreen-ui";
import { mapOptionValueToTranslationKey } from "../../utils/mappers";

// TODO: @maher move to UI KIT
export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacing.s};

  & > div {
    margin-right: ${spacing.s};
  }

  & > div:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${mobileTreshold}px) {
    flex-direction: column;
    gap: ${spacing.s};

    & > div {
      margin-right: 0;
    }

    & > button {
      margin-top: ${spacing.m};
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
`;

interface FormikTextInputFieldprops {
  touched: FormikTouched<object>;
  errors: FormikErrors<object>;
  path: string;
  label?: string | undefined;
  placeHolder?: string | undefined;
  isRequired?: boolean;
  type?: string | undefined;
  fullWidth?: boolean;
}

interface FormikSelectprops {
  OptionsArray: Readonly<Array<string>>;
  touched: FormikTouched<object>;
  errors: FormikErrors<object>;
  path: string;
  label?: string | undefined;
  placeHolder?: string;
  fullWidth?: boolean;
  t: (option: string) => string;
  isRequired?: boolean;
  differentTranlsationkeys?: boolean;
}

interface FormikTextareaProps {
  touched: FormikTouched<object>;
  errors: FormikErrors<object>;
  path: string;
  label?: string | undefined;
  placeHolder?: string | undefined;
  isRequired?: boolean;
  fullWidth?: boolean;
}

interface CheckboxGroupProps {
  path: string;
  elementsArray: Readonly<Array<string>>;
  t: (option: string) => string;
  values: any;
}

export const FormikTextInputField = ({
  touched,
  errors,
  path,
  label = undefined,
  placeHolder = undefined,
  isRequired = false,
  type = undefined,
  fullWidth = true,
}: FormikTextInputFieldprops) => (
  <RowWrapper fullWidth={fullWidth}>
    <Field label={label} isRequired={isRequired} error={_.get(touched, path) ? _.get(errors, path) : undefined}>
      <FormikField name={path}>
        {({ field }: FieldProps) => (
          <Input
            {...field}
            placeholder={placeHolder}
            type={type}
            isInvalid={_.get(touched, path) && _.get(errors, path) !== undefined}
          />
        )}
      </FormikField>
    </Field>
  </RowWrapper>
);

export const FormikSelect = ({
  OptionsArray,
  touched,
  errors,
  path,
  label = undefined,
  placeHolder = "",
  fullWidth = true,
  t,
  isRequired = false,
  differentTranlsationkeys = false,
}: FormikSelectprops) => (
  <RowWrapper fullWidth={fullWidth}>
    <Field isRequired={isRequired} label={label} error={_.get(touched, path) ? _.get(errors, path) : undefined}>
      <FormikField name={path}>
        {({ field }: FieldProps) => (
          <Select
            defaultValue="default"
            {...field}
            isInvalid={_.get(touched, path) && _.get(errors, path) !== undefined}>
            <option disabled key={"default"} value="default">
              {placeHolder}
            </option>
            {OptionsArray.map((option: string) => (
              <option key={option} value={option}>
                {differentTranlsationkeys ? t(mapOptionValueToTranslationKey(option)) : t(option)}
              </option>
            ))}
          </Select>
        )}
      </FormikField>
    </Field>
  </RowWrapper>
);

export const FormikTextarea = ({
  touched,
  errors,
  path,
  label = undefined,
  placeHolder = undefined,
  isRequired = false,
  fullWidth = true,
}: FormikTextareaProps) => (
  <RowWrapper fullWidth={fullWidth}>
    <Field isRequired={isRequired} label={label}>
      <FormikField name={path}>
        {({ field }: FieldProps) => (
          <Textarea
            placeholder={placeHolder}
            {...field}
            isInvalid={_.get(touched, path) && _.get(errors, path) !== undefined}
          />
        )}
      </FormikField>
    </Field>
  </RowWrapper>
);

export const CheckboxGroup = ({ path, elementsArray, t, values }: CheckboxGroupProps) => (
  <>
    {elementsArray.map((element: string) => (
      <FormikField name={`${path}.${element}`} key={element}>
        {({ field }: FieldProps) => (
          <>
            <StyledCheckbox {...field} checked={values[path][element]} label={<p>{t(element)}</p>} />
          </>
        )}
      </FormikField>
    ))}
  </>
);

const RowWrapper = ({ fullWidth, children }: { fullWidth: boolean; children: JSX.Element }) =>
  fullWidth ? <Row>{children}</Row> : children;
