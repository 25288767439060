import { AcceptedLanguage } from "types";

/* -------------------------------------
  OBJECT UTILS CONSTS (Object properties)
------------------------------------- */
export const appointmentLocationArray = ["on_premise", "from_home", "virtually", "other"] as const;

export const appointmentRecurringFrequencyArray = ["daily", "weekly", "bi_weekly", "monthly"] as const;

export const contactDaysArray = ["weekdays", "weekends"] as const;

export const contactPersonArray = ["individual", "caregiver"] as const;

export const contactTimeArray = ["am", "pm", "evenings"] as const;

export const fieldTypesArray = ["text", "number", "radio", "checkbox", "date"] as const;

export const fieldValidationArray = ["email", "phone_number", "ramq_number", "length"] as const;

export const gendersArray = ["male", "female", "other", "undisclosed"] as const;

export const patientUserRelationsArray = ["self", "family", "friend", "patient", "other"] as const;

export const providerOccupationArray = ["physio", "kiro", "nutritionist"] as const;

export const provincesArray = ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"] as const;

export const roleArray = ["super_admin", "admin"] as const;

/* -------------------------------------
  SCORE CONSTS
------------------------------------- */
export const defaultAnswerScore = 0;

export const badScoreCategoryTreshold = 2;

export const scoreCategoriesArray = [0, 1, 2, 3] as const;

/* -------------------------------------
  LANGUAGE UTILS
------------------------------------- */
export const acceptedLanguageArray = ["en", "fr"] as const;

export const defaultLanguage: AcceptedLanguage = "en";

/* -------------------------------------
  AWS RES PAYLOADS AND LOGGING
------------------------------------- */
export const deliveryMediumsArray = ["email", "sms"] as const;
