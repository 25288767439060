import styled from "styled-components";
import { Button, colors, componentBorderWidth, spacing } from "ui-kit";

export default styled(Button)`
  visibility: visible !important;
  border-width: ${componentBorderWidth};
  min-height: ${spacing.xxl};
  height: auto;
  white-space: normal;
  padding: ${spacing.s} ${spacing.m};
  border-radius: ${spacing.l};
  color: ${(props) => (props.isSelected ? colors.primaryBlue : colors.almostBlack)};
  background-color: ${(props) => (props.isSelected ? colors.lightBlue : colors.white)};
  border-color: ${(props) => (props.isSelected ? colors.secondaryBlue : colors.lightGrey)};

  &:hover,
  &:focus,
  &:active {
    border-width: ${componentBorderWidth} !important;
    color: ${colors.primaryBlue} !important;
    border-color: ${colors.secondaryBlue} !important;
    background-color: ${(props) => (props.isSelected ? colors.lightBlue : colors.white)} !important;
  }
`;
