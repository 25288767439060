import { Dispatch, SetStateAction } from "react";
import { TFunction } from "react-i18next";
import styled from "styled-components";
import { Button, colors, Container, mobileTreshold, spacing } from "ui-kit";
import { AvatarInfo } from "../../components";
import { useNavigate } from "react-router-dom";
import { useFormStore } from "../../stores";

export const CONTACT_BAR_HEIGHT_DESKTOP = spacing.xxl;
export const CONTACT_BAR_HEIGHT_MOBILE = "173px";

const ContactBarWrapper = styled.div`
  width: 100%;
  height: var(--contact-bar-height);
  padding: ${spacing.s} 0;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${colors.thirdBlue};
`;

const ContactBarContainer = styled(Container)`
  display: flex;
  justify-content: center;
  gap: ${spacing.s};
  align-items: center;
  padding: 0;
  max-height: 100%;

  & > .button {
    flex-shrink: 0;
  }

  p {
    color: ${colors.white};
  }

  @media (max-width: ${mobileTreshold}px) {
    flex-direction: column;
    padding: 0px ${spacing.m};
  }
`;

interface ContactBarProps {
  setContactFormIsShown: Dispatch<SetStateAction<boolean>>;
  t: TFunction<"assessment">;
  showHomeButton: boolean;
}

const ContactBar = ({ setContactFormIsShown, t, showHomeButton }: ContactBarProps) => {
  const navigate = useNavigate();
  const { resetForm } = useFormStore();

  const buttonParams = showHomeButton
    ? {
        text: t("homeButton"),
        onClick: () => {
          navigate("/home");
          resetForm();
        },
      }
    : { text: t("contactMeButton"), onClick: () => setContactFormIsShown(true) };

  return (
    <ContactBarWrapper>
      <ContactBarContainer>
        <AvatarInfo>
          <p>Venenatis tincidunt odio egestas id neque. Venenatis tincidunt odio egestas id neque.</p>
        </AvatarInfo>
        <Button className="button" appearance={"secondary"} onClick={() => buttonParams.onClick()}>
          {buttonParams.text}
        </Button>
      </ContactBarContainer>
    </ContactBarWrapper>
  );
};

export default ContactBar;
