import { SmallTickIcon } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { mapTextToAcceptedLanguage } from "shared";
import styled from "styled-components";
import {
  Button,
  colors,
  Container,
  fonts,
  HomeIllustration,
  mobileTreshold,
  navBarHeight,
  navBarWidth,
  spacing,
} from "ui-kit";
import { PageWrapper } from "../../components";
import { useFormStore } from "../../stores";
import { fetchFrontPageText } from "../../translations";
import routes from "../../utils/routes";

const HomePageWrapper = styled(PageWrapper)`
  overflow-x: hidden;
`;

const HomeContainer = styled(Container)`
  display: flex;
  align-items: center;
  max-width: ${navBarWidth};
  margin-top: -${navBarHeight};
  padding-top: ${navBarHeight};
  min-height: 80vh;
  overflow: visible;

  h1 {
    color: ${colors.neutral};
  }

  ul {
    padding: 0;
    list-style: none;
    margin: ${spacing.xl} 0;

    font-family: ${fonts.body2.family};
    font-size: ${fonts.body2.size};
    font-weight: ${fonts.body2.weight};
  }

  ul li {
    margin-bottom: ${spacing.m};
    color: ${colors.neutral};
    display: flex;
    align-items: center;
    text-align: left;
  }

  ul li svg {
    margin-right: ${spacing.s};
    color: ${colors.primaryBlue};
    min-width: 20px;
  }
  button {
    height: auto;
    padding: ${spacing.xs} ${spacing.xl};
  }

  button > span {
    display: flex;
    flex-direction: column;
    line-height: 130%;
  }

  button > span > label {
    pointer-events: none;
  }

  @media (max-width: ${mobileTreshold}px) {
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    height: auto;
    margin-top: 32px;
    padding-top: 0;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    ul {
      margin: ${spacing.m} 0;
    }

    button {
      width: 100%;
      align-self: flex-end;
      padding: ${spacing.xs};
    }
  }
`;

const SubContainer = styled.div`
  display: flex;
`;

const DesktopImage = styled(HomeIllustration)`
  margin-bottom: -200px;
  margin-left: 100px;
  margin-right: -50px;

  @media (max-width: ${mobileTreshold}px) {
    display: none;
  }
`;

const MobileImage = styled(HomeIllustration)`
  display: none;
  max-width: 200px;
  margin: ${spacing.m} auto;
  height: auto;
  max-width: 70%;

  @media (max-width: ${mobileTreshold}px) {
    display: block;
  }
`;

export const HomePage = () => {
  const navigate = useNavigate();
  const formStore = useFormStore();
  const { t, i18n } = useTranslation("home");
  const language = mapTextToAcceptedLanguage(i18n.language);
  const ONE_HOUR = 60 * 60 * 1000;

  // Network

  const { mutateAsync: fetchForms, isLoading } = useMutation(() => formStore.fetchForms());

  const { data } = useQuery(["frontPageText", language], () => fetchFrontPageText(language), { staleTime: ONE_HOUR });

  // Handlers

  const onClickStart = async () => {
    await fetchForms();

    navigate(routes.form.path);
  };

  return (
    <HomePageWrapper withNav lightBackground>
      <HomeContainer>
        <SubContainer>
          <div>
            <h1>{t("header")}</h1>
            <MobileImage />
            <ul>
              <li>
                <SmallTickIcon />
                {data && data[0]}
              </li>
              <li>
                <SmallTickIcon />
                {data && data[1]}
              </li>
              <li>
                <SmallTickIcon />
                {data && data[2]}
              </li>
            </ul>

            <Button appearance="primary" onClick={onClickStart} isLoading={isLoading}>
              {/* TODO: If survey is started, change label to continue survey */}
              <span>
                {t("start")}
                <label>{t("surveyTime")}</label>
              </span>
            </Button>
          </div>
          <DesktopImage />
        </SubContainer>
      </HomeContainer>
    </HomePageWrapper>
  );
};
