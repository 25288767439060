import React from "react";

export const DIcon = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg {...props} width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0136719 21.4001V0.600098H7.42701C11.1248 0.600098 13.8092 1.51565 15.4803 3.34677C17.1514 5.1601 17.987 7.71121 17.987 11.0001C17.987 14.289 17.1514 16.849 15.4803 18.6801C13.8092 20.4934 11.1248 21.4001 7.42701 21.4001H0.0136719ZM4.49367 17.9334H7.16034C8.52923 17.9334 9.66701 17.729 10.5737 17.3201C11.4981 16.8934 12.1915 16.1823 12.6537 15.1868C13.1159 14.1734 13.347 12.7779 13.347 11.0001C13.347 9.22232 13.1159 7.83565 12.6537 6.8401C12.1915 5.82677 11.4981 5.11565 10.5737 4.70676C9.66701 4.2801 8.52923 4.06676 7.16034 4.06676H4.49367V17.9334Z"
      fill="#7D2828"
    />
  </svg>
);
