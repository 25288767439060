import React from "react";

export const BIcon = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg {...props} width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.679688 21.4001V0.600098H8.83969C11.4352 0.600098 13.3108 1.07121 14.4664 2.01343C15.6219 2.95565 16.1997 4.22677 16.1997 5.82677C16.1997 6.85788 15.9508 7.69343 15.453 8.33343C14.9552 8.97343 14.1997 9.44454 13.1864 9.74677V10.3868C14.5375 10.689 15.5597 11.2223 16.253 11.9868C16.9641 12.7512 17.3197 13.8623 17.3197 15.3201C17.3197 17.1512 16.7152 18.6268 15.5064 19.7468C14.3152 20.849 12.3419 21.4001 9.58635 21.4001H0.679688ZM4.94635 18.2001H8.99969C10.3508 18.2001 11.3286 17.9334 11.933 17.4001C12.5375 16.849 12.8397 16.1023 12.8397 15.1601C12.8397 14.1112 12.5019 13.3468 11.8264 12.8668C11.1508 12.369 10.2086 12.1201 8.99969 12.1201H4.94635V18.2001ZM4.94635 8.97343H8.51969C9.53302 8.97343 10.333 8.76899 10.9197 8.3601C11.5241 7.93343 11.8264 7.27565 11.8264 6.38677C11.8264 4.66232 10.7241 3.8001 8.51969 3.8001H4.94635V8.97343Z"
      fill="#317159"
    />
  </svg>
);
