import styled from "styled-components";
import { ScoreCategory } from "shared";
import { colors, mobileTreshold, spacing } from "ui-kit";
import Score from "./score";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${colors.white};
  border-radius: ${spacing.l};
  padding: ${spacing.l} ${spacing.xl};
  margin-top: ${spacing.l};
  box-sizing: border-box;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${spacing.xl};
    min-width: 100px;
  }

  & > div > h2 {
    text-align: center;
    margin-bottom: ${spacing.xs};
  }

  @media (max-width: ${mobileTreshold}px) {
    flex-direction: column;
    align-items: flex-start;
    padding: ${spacing.m};

    & > div {
      flex-direction: row-reverse;
      justify-content: flex-end;
      min-width: 100%;
    }

    & > div > h2 {
      margin-left: ${spacing.xs};
      margin-bottom: 0;
    }

    & > p {
      margin-top: ${spacing.l};
    }
  }
`;

interface AssessmentCardProps extends React.HTMLAttributes<HTMLDivElement> {
  scoreCategory: ScoreCategory;
  formName: string;
  feedback: string;
}

const AssessmentCard = (props: AssessmentCardProps) => {
  return (
    <Wrapper {...props}>
      <div>
        <h2>{props.formName}</h2>
        <Score scoreCategory={props.scoreCategory} />
      </div>
      <p>{props.feedback}</p>
    </Wrapper>
  );
};

export default AssessmentCard;
